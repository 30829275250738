import { TokenUserRoles } from '../../../api/users/schemas/Users';

export const BadgeRole = ({
  role,
  classNames = 'h-6 text-xs px-2',
}: {
  role: string;
  classNames?: string;
}) => {
  if (
    !role ||
    (role &&
      [
        TokenUserRoles.EARLY_ADOPTER,
        TokenUserRoles.GRANITE_BETA_TESTER,
      ].includes(role as TokenUserRoles))
  )
    return null;
  const normalizeRole = (role: string) => {
    return role.replace(/ /g, '-').toLowerCase();
  };

  const normalizedRole = normalizeRole(role);
  const getBackgroundColor = () => {
    switch (normalizedRole) {
      case TokenUserRoles.USER:
        return 'bg-user-role-basic-user';
      case TokenUserRoles.COMPANY_ADMIN:
        return 'bg-user-role-admin';
      case TokenUserRoles.SUPER_ADMIN:
        return 'bg-user-role-super-user';
      case TokenUserRoles.WELCOME_USER:
        return 'bg-status-warning-default';
      default:
        return 'bg-user-role-basic-user';
    }
  };
  const getColor = () => {
    switch (normalizedRole) {
      case TokenUserRoles.USER:
      case TokenUserRoles.WELCOME_USER:
        return 'text-content-flip-default';
      case TokenUserRoles.COMPANY_ADMIN:
      case TokenUserRoles.SUPER_ADMIN:
        return 'text-content-base-default';
      default:
        return 'text-content-flip-default';
    }
  };
  const getText = () => {
    switch (normalizedRole) {
      case TokenUserRoles.VIEWER:
        return 'Viewer';
      case TokenUserRoles.USER:
        return 'User';
      case TokenUserRoles.COMPANY_ADMIN:
        return 'Company Admin';
      case TokenUserRoles.SUPER_ADMIN:
        return 'Super Admin';
      case TokenUserRoles.WELCOME_USER:
        return 'Welcome User';
      default:
        return 'Viewer';
    }
  };
  return (
    <div
      className={`${getBackgroundColor()} ${getColor()} flex items-center justify-center rounded-lg font-bold ${classNames}`}
    >
      <p className="w-max">{getText()}</p>
    </div>
  );
};
