import { useAuth0 } from '@auth0/auth0-react';
import { Notification as NotificationType } from 'api/notifications/schema';
import { useCallback, useEffect } from 'react';
import { CloseButtonProps, toast } from 'react-toastify';
import { createSocketInstance } from 'websocket';
import { Close } from 'react-ionicons';
import { useNotifications } from 'hooks/useNotifications';
import Card from './Card';
import { useInAppNotifications } from './InAppNotificationsContext';

const socket = createSocketInstance('/notifications');
const NOTIFICATION_TIMEOUT = 10_000; // 10 seconds, this is only used if notification doesn't require dismissal

export const InAppNotifications = () => {
  const { user } = useAuth0();
  const { markNotificationAsRead, invalidateQuery } = useNotifications();
  const { filter } = useInAppNotifications();

  useEffect(() => {
    // request user permission for notifications if they haven't already done so when page loads
    if (typeof window !== 'undefined' && 'Notification' in window) {
      if (
        Notification.permission !== 'denied' &&
        Notification.permission !== 'granted'
      ) {
        Notification.requestPermission();
      }
    }
  }, []);

  const onInAppNotification = useCallback(
    (notification: NotificationType) => {
      if (notification.organization_id !== user?.org_id) return;

      if (filter(notification)) return;

      invalidateQuery();
      const requiresDismissal =
        notification.event.event_type.event_group.requires_dismissal;
      toast(<Card notification={notification} />, {
        autoClose: requiresDismissal ? false : NOTIFICATION_TIMEOUT,
        position: 'top-right',
        draggable: false,
        className:
          '!bg-background-base-surface-2 flex p-4 rounded-lg justify-between w-[440px] relative',
        closeButton: (props: CloseButtonProps) => (
          <div
            className="absolute right-6 top-6 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              markNotificationAsRead(notification, true);
              props.closeToast(e);
            }}
          >
            <Close
              height={'24px'}
              width={'24px'}
              cssClasses="!fill-content-base-subdued"
            />
          </div>
        ),
      });
      if (Notification.permission === 'granted') {
        new Notification(notification.title, {
          body: notification.subtitle,
          icon: '/logo512.png',
        });
      }
    },
    [user, invalidateQuery, markNotificationAsRead, filter],
  );

  useEffect(() => {
    if (user) {
      // the auth data will be sent when creating the connection with the server.
      // this data is available on the "on_connect" event
      socket.auth = user;
      socket.connect();
    }
    return () => {
      socket.disconnect();
    };
  }, [user]);

  useEffect(() => {
    socket.on('in_app_notification', onInAppNotification);

    return () => {
      socket.off('in_app_notification', onInAppNotification);
    };
  }, [onInAppNotification]);

  return null;
};
