import { z } from 'zod';

export const UsersResponseSchema = z.object({
  name: z.string(),
  role: z.string(),
  status: z.string(),
  email: z.string(),
  phone_number: z.string(),
  created_at: z.string(),
  invited_at: z.string(),
});

export type UsersResponse = z.infer<typeof UsersResponseSchema>;

export const GetUsersParamsSchema = z
  .object({
    page: z.number(),
    page_size: z.number(),
    pending: z.boolean(),
  })
  .partial();

export type GetUsersParams = z.infer<typeof GetUsersParamsSchema>;

export enum TokenUserRoles {
  VIEWER = 'viewer',
  USER = 'user',
  COMPANY_ADMIN = 'company-admin',
  SUPER_ADMIN = 'super-admin',
  GRANITE_BETA_TESTER = 'granite-beta-tester',
  EARLY_ADOPTER = 'early-adopter',
  QR_ADMIN = 'qr-admin',
  WELCOME_USER = 'welcome-user',
}

export const TokenUserRolesSet = new Set([
  TokenUserRoles.VIEWER,
  TokenUserRoles.USER,
  TokenUserRoles.COMPANY_ADMIN,
  TokenUserRoles.SUPER_ADMIN,
  TokenUserRoles.GRANITE_BETA_TESTER,
  TokenUserRoles.EARLY_ADOPTER,
  TokenUserRoles.QR_ADMIN,
  TokenUserRoles.WELCOME_USER,
]);

export enum G360Pillars {
  AccessExpress = 'access-express',
  NOCExpress = 'noc-express',
}

export const G360PillarsSet = new Set([
  G360Pillars.AccessExpress,
  G360Pillars.NOCExpress,
]);

export const canViewAccessExpress = (userRole: TokenUserRoles): boolean => {
  return (
    userRole === TokenUserRoles.GRANITE_BETA_TESTER ||
    userRole === TokenUserRoles.EARLY_ADOPTER ||
    userRole === TokenUserRoles.QR_ADMIN
  );
};
