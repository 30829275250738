import React from 'react';
import format from 'date-fns/format';
import { ButtonGranite, LoaderGranite } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import PrimaryDetails from './PrimaryDetails';
import { BadgeRole } from 'components/V2/BadgeRole/BadgeRole';
import { useAuth0User } from 'hooks/useAuth0User';
import { getProfileUser, getUserByEmail } from 'api/users/api';
import { useQuery } from 'react-query';
import { plainTextToPhoneNumber } from 'shared/util/util';
import Avatar from 'components/Avatar';
import clsx from 'clsx';
import { UserRoles } from 'api/users/schemas/UserRole';
import { Key } from 'react-ionicons';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useChangePasswordMutation } from 'hooks/useChangePasswordMutation';

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { roles, email, companyName } = useAuth0User();
  const { data: profileUser, isLoading: profileIsLoading } = useQuery(
    ['user-profile', email],
    () => getProfileUser(),
    {
      refetchOnWindowFocus: false,
      enabled: !id,
    },
  );
  const { data: user, isLoading: userIsLoading } = useQuery(
    ['user', id],
    () => getUserByEmail({ email: id! }),
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: !!id,
    },
  );

  const data = id
    ? { ...user, email: id, roles: user?.role ? [user.role] : [] }
    : { ...profileUser, email, roles };
  const isLoading = id ? userIsLoading : profileIsLoading;

  const navigate = useNavigate();
  const breadcrumbs = [
    {
      icon: 'home',
      label: 'User management',
      onClick: () => navigate('/users'),
    },
  ];

  const userStatusClassName = data?.deactivated
    ? 'bg-user-status-deactivated'
    : 'bg-user-status-active';

  const dateAdded = data?.invited_at || data?.created_at;

  const changePasswordMutation = useChangePasswordMutation();

  return (
    <div className="w-full p-8">
      {isLoading ? (
        <LoaderGranite />
      ) : (
        <div className="invite-user mx-auto flex w-full max-w-[1376px] flex-col items-start justify-start">
          <div className="mb-12 flex w-full items-center justify-between gap-6">
            <div className="flex items-end justify-start gap-6 ">
              <PageTitle title={data?.name ?? ''} breadcrumbs={breadcrumbs} />
              <div>
                {data.roles.map((role) => (
                  <BadgeRole
                    key={role}
                    role={role}
                    classNames="text-base py-1.5 h-[36px] px-3"
                  />
                ))}
              </div>
            </div>
            <ButtonGranite
              label={'Edit user'}
              size="large"
              onClick={() => {
                id ? navigate(`/users/edit/${id}`) : navigate(`/profile/edit`);
              }}
            />
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-6">
            <div className="col-span-12 flex w-full items-center justify-between gap-12 rounded bg-background-base-surface-2 px-6 py-4">
              <div className="flex w-full items-center justify-start gap-3">
                <span
                  className={clsx(`h-4 w-4 rounded-full`, userStatusClassName)}
                ></span>
                <h2 className="text-xl font-semibold text-content-base-default">
                  {data?.deactivated ? 'Deactivated' : 'Active'}
                </h2>
              </div>
              <div className="flex w-full flex-col items-end justify-start">
                <p className="text-base font-semibold text-content-base-subdued">
                  Date Added
                </p>
                <h2 className="text-base font-semibold text-content-base-default">
                  {dateAdded
                    ? format(new Date(dateAdded), 'MM/dd/yyyy')
                    : ' -- '}
                </h2>
              </div>
            </div>
            <div className="grid h-full w-full grid-cols-12 overflow-hidden rounded">
              <div className="col-span-4 flex h-full w-full flex-col items-start justify-start gap-4 bg-background-base-surface-3 px-8 py-6">
                <h2 className="text-2xl font-bold text-content-base-default">
                  User Details
                </h2>
                <Avatar width="w-20" height="h-20" name={data?.name ?? 'N/A'} />
                <div className="flex w-full flex-col items-start justify-start gap-4">
                  <PrimaryDetails label={'Name'} value={data?.name ?? 'N/A'} />
                  <PrimaryDetails label={'Email'} value={data.email ?? 'N/A'} />
                  <PrimaryDetails
                    label={'Secondary email'}
                    value={data?.secondary_email ?? 'N/A'}
                  />
                  <PrimaryDetails
                    label={'Phone number'}
                    value={
                      data?.phone_number
                        ? plainTextToPhoneNumber(data.phone_number)
                        : 'No phone number'
                    }
                  />
                  {data?.secondary_phone_number && (
                    <PrimaryDetails
                      label={'Secondary phone number'}
                      value={plainTextToPhoneNumber(
                        data?.secondary_phone_number,
                      )}
                    />
                  )}
                  <PrimaryDetails
                    label={'Company'}
                    value={companyName ?? 'N/A'}
                  />
                  <PrimaryDetails
                    label={'Company title'}
                    value={data?.company_title ? data?.company_title : 'N/A'}
                  />
                  <PrimaryDetails
                    label={'Department'}
                    value={data?.department ? data?.department : 'N/A'}
                  />
                </div>
                {!id && (
                  <GraniteButton
                    variant="secondary"
                    size="large"
                    className="mt-10 box-border w-full"
                    onClick={() => changePasswordMutation.mutate(email!)}
                  >
                    Reset password <Key />
                  </GraniteButton>
                )}
              </div>
              <div className="col-span-8 flex h-full w-full flex-col items-start justify-start gap-8 bg-background-base-surface-2 px-8 pb-12 pt-6">
                <div className="flex w-full flex-col items-start justify-start gap-4">
                  <h2 className="text-2xl font-semibold text-content-base-default">
                    Role & permissions
                  </h2>
                  <div className="flex w-full items-start justify-start gap-4">
                    <div className="max-w-[130px]">
                      <BadgeRole
                        role={id && data.role ? data.role : roles[0]}
                      />
                    </div>
                    <p className="w-full text-base font-bold text-content-base-default">
                      {data.role === UserRoles.enum.Viewer &&
                        'This user will be limited to read only permissions'}
                      {data.role === UserRoles.enum.User &&
                        'This user can have read & write or read only permissions'}
                      {data.role === UserRoles.enum['Company Admin'] ||
                        (data.role === UserRoles.Enum['Super Admin'] &&
                          'This user will have full read & write permissions and the ability to manage users')}
                      {data.role === UserRoles.enum['Welcome User'] &&
                        'Pending role assignment'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
