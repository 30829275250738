import Tabs from 'components/Table/Tabs';
import React, { useState } from 'react';
import GroupForm from './GroupForm';
import DevicesForm from './DevicesForm';
import { ContactGroupListItemType } from '../ContactGroups';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { FormProvider, useForm } from 'react-hook-form';
import { ContactGroupFormType, ContactGroupSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import DeleteGroup from './DeleteGroup';
import DeactivateGroup from '../DeactivateGroup';
import ReactiveGroup from '../ReactiveGroup';
import { DisplayForRole } from 'components/DisplayForRole/DisplayForRole';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { useAuth0User } from 'hooks/useAuth0User';
import RemoveMeFromGroup from '../RemoveMeFromGroup';
import { TabsTypeEnum } from '../../utils';

interface ContactGroupEditProps {
  close: () => void;
  defaultValue: ContactGroupListItemType | null;
}

const ContactGroupEditForm: React.FC<ContactGroupEditProps> = ({
  close,
  defaultValue,
}) => {
  const { isViewerRole } = useAuth0User();

  const [activeTab, setActiveTab] = useState(TabsTypeEnum.group);

  const methods = useForm<ContactGroupFormType>({
    resolver: zodResolver(ContactGroupSchema),
    defaultValues: {
      name: defaultValue?.name,
      recipients: defaultValue?.recipients || [],
      devices: defaultValue?.devices,
    },
  });

  const onSubmit = (data: ContactGroupFormType) => {
    console.log(data, 'tung');
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex items-center justify-between rounded-t-lg bg-background-base-surface-3 px-8 py-6 font-bold leading-9">
          <h1 className="text-2.5xl text-content-base-default">
            {isViewerRole ? 'View' : 'Edit'} contact group
          </h1>
          <p className="font-bold text-white">
            <span
              className={clsx(
                'mr-2 inline-flex h-2.5 w-2.5 rounded-full',
                defaultValue?.status === 'active' && 'bg-green-400',
                defaultValue?.status === 'inactive' && 'bg-neutral-500',
              )}
            />
            {defaultValue?.status[0].toUpperCase()}
            {defaultValue?.status.slice(1)}
          </p>
        </div>
        <div className="p-8">
          <Tabs
            className="mb-8"
            variant="medium"
            defaultActiveTab={TabsTypeEnum.group}
            tabs={[
              {
                title: 'Group details',
                onClick: () => setActiveTab(TabsTypeEnum.group),
              },
              {
                title: 'Devices',
                onClick: () => setActiveTab(TabsTypeEnum.devices),
              },
            ]}
          />
          {activeTab === TabsTypeEnum.group ? (
            <>
              <GroupForm />
              <div className="mt-8 flex gap-4">
                {isViewerRole && (
                  <RemoveMeFromGroup label="Remove me from this group" />
                )}
                <DisplayForRole roles={[TokenUserRoles.COMPANY_ADMIN]}>
                  <>
                    {defaultValue?.status === 'inactive' && (
                      <ReactiveGroup label=" Reactivate group" />
                    )}
                    {defaultValue?.status === 'active' && (
                      <DeactivateGroup label="Deactivate group" />
                    )}
                    <DeleteGroup />
                  </>
                </DisplayForRole>
              </div>
            </>
          ) : null}

          {activeTab === TabsTypeEnum.devices ? <DevicesForm /> : null}
          <div className="mt-12 flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={close}>
              Cancel
            </GraniteButton>
            <DisplayForRole roles={[TokenUserRoles.COMPANY_ADMIN]}>
              <GraniteButton size="large" type="submit">
                Save Changes
              </GraniteButton>
            </DisplayForRole>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ContactGroupEditForm;
