import { ColumnDef } from '@tanstack/react-table';
import Searchbar from 'components/Table/SearchBar';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { TableTitle, NumericCell } from 'components/Table/Table.styles';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { useState } from 'react';
import { People } from 'react-ionicons';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { getContactGroupsMockData } from '../utils';
import { PaginableRequest } from 'api/common-schemas';
import clsx from 'clsx';
import Tag from 'components/Tag';
import { DisplayForRole } from 'components/DisplayForRole/DisplayForRole';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { useAuth0User } from 'hooks/useAuth0User';
import { Modal } from 'components/Modal/Modal';
import { useModal } from 'hooks/useModal';
import ContactGroupEditForm from './Form/ContactGroupEditForm';
import AddContactGroup from './AddContactGroup';
import DeactivateGroup from './DeactivateGroup';
import ReactiveGroup from './ReactiveGroup';
import RemoveMeFromGroup from './RemoveMeFromGroup';

export type ContactGroupListItemType = {
  name: string;
  recipients: { email: string }[];
  status: 'active' | 'inactive';
  devices: DeviceType[];
  device_number: number;
};

export type DeviceType = { name: string; serial: string; account: string };

const ContactGroups = () => {
  const [search, setSearch] = useState<string>('');
  const [editGroup, setEditGroup] = useState<ContactGroupListItemType | null>(
    null,
  );

  const { isViewerRole } = useAuth0User();

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'name', desc: true }],
  });

  const getContactGroups = (args: PaginableRequest) => {
    // API call
    return Promise.resolve(getContactGroupsMockData(args));
  };

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    getContactGroups,
    {
      search,
      sortingQueryParams,
    },
    ['alert-contact-groups-table', search, sortingQueryParams],
    {
      refetchOnMount: true,
    },
  );

  const { open, isOpen, close } = useModal();

  const onEdit = (group: ContactGroupListItemType) => {
    setEditGroup(group);
    open();
  };

  const tableHeaders: ColumnDef<ContactGroupListItemType>[] = [
    {
      header: 'Group name',
      accessorKey: 'name',
    },
    {
      header: 'Email recipients',
      accessorKey: 'emails',
      cell: (row) => (
        <div className="flex gap-2">
          {row.row.original.recipients.map((r, i) => (
            <Tag
              key={i}
              content={{ type: 'label', label: r.email }}
              status="neutral"
              style="default"
            />
          ))}
        </div>
      ),
    },
    {
      header: 'Devices',
      accessorKey: 'device_number',
      cell: (row) => (
        <NumericCell>{row.row.original.device_number}</NumericCell>
      ),
      meta: {
        align: 'right',
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row) => (
        <p>
          <span
            className={clsx(
              'mr-2 inline-flex h-2.5 w-2.5 rounded-full',
              row.row.original.status === 'active' && 'bg-green-400',
              row.row.original.status === 'inactive' && 'bg-neutral-500',
            )}
          />
          {row.row.original.status[0].toUpperCase()}
          {row.row.original.status.slice(1)}
        </p>
      ),
    },
    {
      header: 'Actions',
      cell: (row) => (
        <div className="mr-1 flex gap-3">
          <GraniteButton
            variant="secondary"
            size="small"
            onClick={() => onEdit(row.row.original)}
          >
            {isViewerRole ? 'View' : 'Edit'}
          </GraniteButton>
          <DisplayForRole roles={[TokenUserRoles.COMPANY_ADMIN]}>
            {row.row.original.status === 'active' && (
              <DeactivateGroup label="Deactivate" size="small" />
            )}
            {row.row.original.status === 'inactive' && (
              <ReactiveGroup label=" Reactivate" size="small" />
            )}
          </DisplayForRole>
          {isViewerRole && <RemoveMeFromGroup label="Remove" size="small" />}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="mb-6 flex items-center justify-between">
        <TableTitle className="!mb-0 flex gap-4">
          <People height="32px" width="32px" color="rgba(248, 250, 252, 1)" />{' '}
          Contact groups
        </TableTitle>
        <DisplayForRole roles={[TokenUserRoles.COMPANY_ADMIN]}>
          <AddContactGroup />
        </DisplayForRole>
      </div>
      <Searchbar
        placeholder="Search by device, account, location"
        onSearch={setSearch}
        onQueryClear={() => {
          setSearch('');
        }}
        clearAllValues={search === ''}
      />
      <div className="mb-16 mt-10 w-full">
        <ServerPaginatedTable
          data={tablePageData}
          columns={tableHeaders}
          title="nocexpress alert contact groups"
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          emptyDataElement={<EmptySearchResults />}
          {...paginatedTableProps}
        />
      </div>
      <Modal
        isVisible={isOpen}
        close={close}
        className="w-full max-w-[836px]"
        enableScrolling
      >
        <ContactGroupEditForm close={close} defaultValue={editGroup} />
      </Modal>
    </div>
  );
};

export default ContactGroups;
