import { Modal } from 'components/Modal/Modal';
import {
  GraniteButton,
  GraniteButtonProps,
} from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { FC } from 'react';

interface RemoveMeFromGroupProps extends Pick<GraniteButtonProps, 'size'> {
  label: string;
}

const RemoveMeFromGroup: FC<RemoveMeFromGroupProps> = ({
  label,
  size = 'medium',
}) => {
  const { open, isOpen, close } = useModal();

  return (
    <>
      <GraniteButton variant="destructive-secondary" size={size} onClick={open}>
        {label}
      </GraniteButton>
      <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
        <div className="p-8">
          <div>
            <h1 className="text-2xl font-bold text-white">
              Are you sure you want to remove yourself from this group?
            </h1>
            <p className="mt-2 text-base font-bold text-neutral-500">
              You’ll no longer receive notifications and will not be able to
              re-add yourself without an administrator.
            </p>
          </div>
          <div className="mt-12 flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton size="large" variant="destructive">
              Yes, remove me from this group
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RemoveMeFromGroup;
