import { ColumnDef } from '@tanstack/react-table';
import { PageTitleGranite } from 'components';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { useCallback, useState } from 'react';
import { TicketIdBadge } from 'screens/TechExpress/TechExpress.styles';
import Searchbar from 'components/Table/SearchBar';
import { getCompanies } from 'api/companies/api';
import { useAuth0User } from 'hooks/useAuth0User';
import { GetCompaniesParams, Company } from 'api/companies/schemas';
import { useAuth0 } from '@auth0/auth0-react';

const Companies: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const { sub, org_id, companyName } = useAuth0User();
  const [search, setSearch] = useState('');
  const getCompaniesQueryFn = useCallback(
    (args?: GetCompaniesParams) =>
      getCompanies(sub!, {
        ...(search ? { search } : {}),
        ...args,
      }),
    [search, sub],
  );
  const { data: tablePageData, ...paginatedTableProps } =
    usePaginatedTable<Company>(
      getCompaniesQueryFn,
      {
        search,
      },
      [
        'auth',
        'companies',
        {
          search,
        },
      ],
      { refetchOnMount: true },
    );

  const switchCompany = (company: Company) => {
    loginWithRedirect({
      authorizationParams: { organization: company.id },
    });
  };

  const CompaniesHeaders: ColumnDef<Company>[] = [
    {
      header: 'Company macnum',
      cell: (row) => (
        <TicketIdBadge>{row.row.original?.metadata?.macnum}</TicketIdBadge>
      ),
      enableSorting: false,
    },
    {
      header: 'Company name',
      accessorKey: 'display_name',
      enableSorting: false,
      cell: (row) => (
        <div className="text-sm font-medium">
          {row.row.original.display_name ?? row.row.original.name}
        </div>
      ),
    },
    {
      header: 'Actions',
      enableSorting: false,
      cell: (row) => {
        return (
          <div className="text-sm font-medium text-content-base-default">
            {row.row.original.id === org_id ? (
              'Current company'
            ) : (
              <GraniteButton
                onClick={() => switchCompany(row.row.original)}
                title=""
                size="small"
                variant="secondary"
              >
                Switch to this company
              </GraniteButton>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <ContentLayout>
      <div className="flex w-full flex-col items-start justify-start">
        <PageTitleGranite title="Companies" />
        <div className="mb-8 mt-6 flex flex-col items-start justify-start gap-2">
          <div>
            <GraniteLabel className="text-base font-bold">
              Current company
            </GraniteLabel>
            <h1 className="text-2xl font-bold text-content-base-default">
              {companyName}
            </h1>
          </div>
          <p className="text-base font-semibold text-content-base-default">
            You are currently accessing Granite360 as a Granite Super Admin from{' '}
            {companyName}.
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-12">
          <Searchbar
            placeholder="Search by macnum or company name"
            onQueryClear={() => setSearch('')}
            onSearch={setSearch}
          />
          <ServerPaginatedTable
            data={tablePageData}
            columns={CompaniesHeaders}
            title="Companies"
            {...paginatedTableProps}
          />
        </div>
      </div>
    </ContentLayout>
  );
};

export default Companies;
