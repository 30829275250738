import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import ContactGroupAddForm from './Form/ContactGroupAddForm';
import { useModal } from 'hooks/useModal';

const AddContactGroup = () => {
  const { open, isOpen, close } = useModal();

  const onAdd = () => {
    open();
  };
  return (
    <>
      <GraniteButton onClick={onAdd}>Add contact group</GraniteButton>
      <Modal
        isVisible={isOpen}
        close={close}
        className="w-full max-w-[836px]"
        enableScrolling
      >
        <ContactGroupAddForm close={close} />
      </Modal>
    </>
  );
};

export default AddContactGroup;
