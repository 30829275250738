import React, { Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  PaginationState,
  Row,
  SortingState,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import {
  DefaultTableContainer,
  TableCell,
  TableHeaderCell,
  TableRow,
} from './Table.styles';
import Pagination, { PaginationProps } from './Pagination';
import { ChevronDown, Download } from 'react-ionicons';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Loader from '../Loader';
import { clsx } from 'clsx';
import { getAlignmentClass } from './utils';

enum TableVariant {
  default = '56px',
  small = '40px',
}

const TableMessage = ({ children }: PropsWithChildren) => (
  <div className="col-span-full flex min-h-[570px] w-full items-center justify-center">
    {children}
  </div>
);
export const DataLoading = () => (
  <TableMessage>
    <Loader />
  </TableMessage>
);

const FetchingError = () => (
  <TableMessage>There was an error loading the results.</TableMessage>
);

const EmptyData = () => <TableMessage>No results found.</TableMessage>;

export interface ServerPaginatedTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  title: string;
  handleRowClick?: (row: Row<T>) => void;
  paginationState: PaginationState;
  onPaginationChange: OnChangeFn<PaginationState>;
  reactTableOptions?: TableOptions<T>;
  itemCount: number;
  pageCount: number;
  sortingState?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  isFetchingData?: boolean;
  isFetchingError?: boolean;
  downloadTableFn?: () => Promise<void>;
  showHeadersWhenEmpty?: boolean;
  showPagination?: boolean;
  emptyDataElement?: ReactNode;
  tourClassName?: string;
  paginationChanged?: (pageSize: number) => void;
  paginationSizeStored?: number;
  elementBeforePagination?: ReactNode;
  paginationVariant?: PaginationProps['variant'];
  variant?: 'small' | 'default';
  pageSizeMenuPlacement?: PaginationProps['pageSizeMenuPlacement'];
  tableContainerClassName?: string;
}

export const ServerPaginatedTable = <T extends object>({
  data,
  columns,
  paginationState,
  onPaginationChange,
  itemCount,
  pageCount,
  title,
  sortingState,
  onSortingChange,
  isFetchingData,
  isFetchingError,
  handleRowClick,
  downloadTableFn,
  showHeadersWhenEmpty = true,
  showPagination = true,
  emptyDataElement = <EmptyData />,
  tourClassName,
  paginationSizeStored,
  paginationChanged,
  elementBeforePagination,
  paginationVariant,
  variant = 'default',
  pageSizeMenuPlacement,
  tableContainerClassName,
}: ServerPaginatedTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: false,
    sortDescFirst: true,
    onPaginationChange,
    onSortingChange,
    pageCount,
    state: {
      pagination: paginationState,
      sorting: sortingState,
    },
  });

  const TableComponent = useMemo(() => {
    if (isFetchingData) return <DataLoading />;
    if (isFetchingError) return <FetchingError />;
    if (data.length === 0) return emptyDataElement;
    return table.getRowModel().rows.map((row, index) => (
      <TableRow
        key={row.id}
        onClick={() => handleRowClick && handleRowClick(row)}
        role="row"
        className={clsx('odd:py-2', index === 0 && tourClassName)}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={cell.id}
            role="cell"
            aria-rowindex={index}
            className={getAlignmentClass(cell.column.columnDef.meta?.align)}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
    ));
  }, [
    data.length,
    emptyDataElement,
    handleRowClick,
    isFetchingData,
    isFetchingError,
    table,
    tourClassName,
  ]);

  const displayHeaders = showHeadersWhenEmpty ? true : data.length > 0;

  return (
    <div
      className="flex w-full flex-col items-start justify-start"
      role="table"
      aria-label={title}
    >
      <div className="w-full overflow-x-auto scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued">
        <DefaultTableContainer
          $columnCount={columns.length}
          variant={TableVariant[variant]}
          className={tableContainerClassName}
        >
          {displayHeaders &&
            table.getHeaderGroups().map((headerGroup) => (
              <Fragment key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeaderCell
                    key={header.id}
                    className={clsx(
                      header.column.getCanSort() && 'cursor-pointer gap-2',
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                    role="columnheader"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.column.getCanSort() ? (
                      <ChevronDown
                        cssClasses={
                          header.column.getIsSorted() === 'asc'
                            ? 'rotate-180'
                            : ''
                        }
                        width="16px"
                        height="16px"
                        color={`rgb(var(--${
                          header.column.getIsSorted()
                            ? 'content-accent-default'
                            : 'content-base-subdued'
                        }))`}
                      />
                    ) : null}
                  </TableHeaderCell>
                ))}
              </Fragment>
            ))}
          {TableComponent}
        </DefaultTableContainer>
      </div>
      {elementBeforePagination ? elementBeforePagination : null}
      <div
        className={clsx(
          'mt-6 flex w-full flex-col items-start justify-start',
          !showPagination && 'hidden',
        )}
      >
        <Pagination
          pageCount={table.getPageCount()}
          totalRows={itemCount}
          currentPage={table.getState().pagination.pageIndex + 1 || 1}
          onPageChange={(page: number) => table.setPageIndex(page - 1)}
          currentRowsShown={data.length}
          paginationSizeStored={paginationSizeStored}
          pageSizeChanged={(page: number) => {
            table.setPageSize(page);
            paginationChanged?.(page);
          }}
          pageSizeMenuPlacement={pageSizeMenuPlacement}
          variant={paginationVariant}
        />
      </div>
      {downloadTableFn && (
        <div className="mt-6 flex w-full items-center justify-end">
          <GraniteButton
            variant="secondary"
            onClick={downloadTableFn}
            className="border-stroke-secondary-default flex cursor-pointer items-center justify-center rounded border text-content-base-default "
          >
            Export table
            <Download color={'inherit'} />
          </GraniteButton>
        </div>
      )}
    </div>
  );
};
