import { FC, ReactNode, useMemo } from 'react';
import { StepDetail, TourSteps } from './steps';
import { useProductTourContext } from 'context/ProductTourContext';
import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Close } from 'react-ionicons';
import { upsertProductTourProgress } from 'api/producttour/api';
import { TourStatus } from 'api/producttour/schemas';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth0User } from 'hooks/useAuth0User';
import { TokenUserRoles } from 'api/users/schemas/Users';

export interface Sections {
  dashboard: number[];
  techexpress: number[];
  nocexpress: number[];
  mobility: number[];
  user_management: number[];
}

export const sections: Sections = {
  dashboard: [TourSteps.WELCOME_MODAL_STEP, 1, 2],
  techexpress: [3, 4, 5, 6, 7, 8, 9, 10],
  nocexpress: [11, 12, 13, 14, 15, 16, 17, 18, 19],
  mobility: [20],
  user_management: [21, 22, 23],
};

const sectionMappings: Record<
  string,
  'Dashboard' | 'TechExpress' | 'NOCExpress' | 'Mobility' | 'User Management'
> = {
  dashboard: 'Dashboard',
  techexpress: 'TechExpress',
  nocexpress: 'NOCExpress',
  mobility: 'Mobility',
  user_management: 'User Management',
};

export const CustomToolTip: FC<{
  steps: StepDetail[];
  step: number;
  title?: ReactNode;
  subtitle: ReactNode;
  disabledNextButton?: boolean;
  isLastStep?: boolean;
  isFirstStep?: boolean;
  className?: string;
}> = ({ title, subtitle, className, steps, step }) => {
  const {
    stepIndex,
    stepHistory,
    setStepHistory,
    progressData,
    setProgressData,
    setRunning,
  } = useProductTourContext();
  const queryClient = useQueryClient();
  const { roles } = useAuth0User();
  const updateTourProgress = useMutation(upsertProductTourProgress, {
    onSuccess: () => {
      queryClient.invalidateQueries('productTour');
    },
  });

  const navigate = useNavigate();

  const hasAccessOnUserManagement = useMemo(() => {
    const acceptableAppRoles = [
      TokenUserRoles.COMPANY_ADMIN,
      TokenUserRoles.SUPER_ADMIN,
    ];
    return roles
      ? roles.some((role) => acceptableAppRoles.includes(role))
      : false;
  }, [roles]);

  const updateProgress = (step: number, status: TourStatus) => {
    const section = Object.keys(sections).find((key) =>
      sections[key as keyof Sections].includes(step),
    );
    const progress = progressData.find((p) => p.step === step);

    if (section) {
      updateTourProgress.mutate({
        step: step,
        section: sectionMappings[section as keyof typeof sectionMappings],
        status,
        id: progress?.id,
      });

      const updatedProgress = progressData.map((p) =>
        p.step === step ? { ...p, status } : p,
      );
      setProgressData(updatedProgress);
    }
  };

  const getNextSectionStep = (
    currentStep: number,
    sectionIndex: number,
  ): number => {
    const _sections = Object.values(sections);

    if (sectionIndex === _sections.length - 1) {
      return steps.length - 2;
    }

    if (
      !hasAccessOnUserManagement &&
      sections.user_management.includes(currentStep)
    ) {
      return steps.length - 1;
    }

    let nextStep = _sections[sectionIndex + 1]?.[0];
    while (
      nextStep < steps.length &&
      steps[nextStep]?.hasAccess &&
      !steps[nextStep]?.hasAccess?.(roles)
    ) {
      nextStep++;
    }

    return nextStep ?? 1;
  };

  const skipSection = (stepIndex: number) => {
    const _sections = Object.values(sections);
    for (let i = 0; i < _sections.length; i++) {
      if (_sections[i].includes(stepIndex)) {
        let nextStep = getNextSectionStep(stepIndex, i);

        while (
          nextStep < steps.length &&
          steps[nextStep]?.hasAccess &&
          !steps[nextStep]?.hasAccess?.(roles)
        ) {
          nextStep++;
        }

        if (nextStep >= steps.length - 1) {
          nextStep = steps.length - 2;
        }

        updateProgress(stepIndex, 'Skipped');
        setStepHistory([...stepHistory, nextStep]);
        return;
      }
    }
  };

  const handlePrevStep = () => {
    const stepsHistoryCopy = [...stepHistory];
    stepsHistoryCopy.pop();
    let prevStep = stepsHistoryCopy[stepsHistoryCopy.length - 1];
    while (
      prevStep >= 0 &&
      steps[prevStep]?.hasAccess &&
      !steps[prevStep].hasAccess?.(roles)
    ) {
      stepsHistoryCopy.pop();
      prevStep = stepsHistoryCopy[stepsHistoryCopy.length - 1];
    }
    if (stepsHistoryCopy.length > 0) {
      setStepHistory(stepsHistoryCopy);
    }
  };

  const handleNextStep = () => {
    const nextStep = steps.findIndex(
      (step, idx) =>
        idx > stepIndex && (!step.hasAccess || step?.hasAccess(roles)),
    );
    updateProgress(stepIndex, 'Completed');
    setStepHistory([...stepHistory, nextStep]);
  };

  const handleClose = () => {
    setStepHistory([...stepHistory, steps.length - 1]);
  };

  const handleLastStep = async () => {
    setRunning(false);
    setStepHistory([0]);
    navigate('/');
  };

  return (
    <div
      className={clsx(
        'w-[356px] max-w-[356px] rounded bg-[#222231]',
        className,
      )}
    >
      <div className="flex justify-end p-4 pb-2">
        {step !== TourSteps.REVISIT_LATER_STEP && (
          <Close
            width="24px"
            height="24px"
            color="#F8FAFC"
            onClick={handleClose}
            cssClasses="cursor-pointer"
          />
        )}
      </div>
      <div className="flex flex-col gap-12 p-4 text-center">
        <div className="flex flex-col gap-2">
          {title && (
            <div className="text-xl font-bold text-content-base-default">
              {title}
            </div>
          )}
          <div className="text-base text-content-base-default">{subtitle}</div>
        </div>
        {[steps.length - 1, steps.length - 2].includes(step) ? (
          <div className="flex items-center justify-center gap-5">
            <GraniteButton
              size="medium"
              variant="primary"
              type="submit"
              onClick={handleLastStep}
            >
              Got it
            </GraniteButton>
          </div>
        ) : (
          <div className="flex items-center justify-between gap-5">
            <GraniteButton
              variant="ghost"
              onClick={() => skipSection(stepIndex)}
            >
              Skip
            </GraniteButton>
            <div className="flex gap-2">
              {stepIndex !== 1 && (
                <GraniteButton
                  size="medium"
                  variant="secondary"
                  type="submit"
                  onClick={() => handlePrevStep()}
                >
                  Back
                </GraniteButton>
              )}
              <GraniteButton
                size="medium"
                variant="primary"
                type="submit"
                onClick={() => handleNextStep()}
              >
                Next
              </GraniteButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
