import { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Tooltip from 'components/Tooltip/Tooltip';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { createPopper } from '@popperjs/core';
import { useModalContext } from 'context/ModalContext';
import { useMutation } from 'react-query';
import { requestUpgrade } from 'api/users/api';
import Loader from 'components/Loader/Loader';
import clsx from 'clsx';

const LOCAL_STORAGE_KEY = 'hasRequestedUpgrade';

export const useUpgradeAccessTooltip = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [hasRequestedUpgrade, setHasRequestedUpgrade] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<number | null>(null);

  const { openModal } = useModalContext();

  const upgradeRequestModal = useMutation(requestUpgrade, {
    onSuccess: () => {
      setHasRequestedUpgrade(true);
      openModal();
      localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
    },
  });

  useEffect(() => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY) === 'true';
    setHasRequestedUpgrade(storedValue);
  }, []);

  useEffect(() => {
    if (isTooltipVisible && referenceElement && tooltipRef.current) {
      const popperInstance = createPopper(
        referenceElement,
        tooltipRef.current,
        {
          placement: 'top',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        },
      );
      return () => {
        popperInstance.destroy();
      };
    }
  }, [isTooltipVisible, referenceElement]);

  const showTooltip = (event: React.MouseEvent<HTMLElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setReferenceElement(event.currentTarget as HTMLElement);
    setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    timerRef.current = window.setTimeout(() => {
      setIsTooltipVisible(false);
    }, 200);
  };

  const handleRequestUpgrade = () => {
    if (upgradeRequestModal.isLoading) return;
    upgradeRequestModal.mutate();
  };

  const keepTooltipVisible = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setIsTooltipVisible(true);
  };

  const renderTooltip = () => {
    if (!isTooltipVisible) return null;

    return ReactDOM.createPortal(
      <div
        ref={tooltipRef}
        className="relative"
        onMouseEnter={keepTooltipVisible}
        onMouseLeave={hideTooltip}
      >
        <Tooltip
          content={
            <div className="overflow-wrap flex flex-col items-start gap-2 break-words">
              <p className="font-bold text-content-base-default">
                Insufficient permissions
              </p>
              <p className="text-sm text-content-base-subdued">
                You do not have the required role to perform this action. Please
                request a role upgrade from your Company Admin.
              </p>
              {hasRequestedUpgrade ? (
                <GraniteButton variant="tertiary" disabled className="mt-3">
                  Role upgrade requested
                </GraniteButton>
              ) : (
                <GraniteButton
                  variant="tertiary"
                  onClick={handleRequestUpgrade}
                  className={clsx(
                    'mt-3',
                    upgradeRequestModal.isLoading &&
                      '!border-button-stroke-tertiary-pressed !cursor-default !bg-button-background-tertiary-pressed !text-button-content-tertiary-pressed',
                  )}
                >
                  Request role upgrade
                  {upgradeRequestModal.isLoading && (
                    <Loader animationClassname="!w-3 !h-3" />
                  )}
                </GraniteButton>
              )}
            </div>
          }
        />
      </div>,
      document.body,
    );
  };

  return {
    showTooltip,
    hideTooltip,
    renderTooltip,
    isTooltipVisible,
    keepTooltipVisible,
    referenceElement,
  };
};
