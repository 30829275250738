import { TokenUserRoles } from 'api/users/schemas/Users';
import React, { ReactNode } from 'react';
import { useWithUserPermissions } from 'hooks/useWithUserPermissions';
import { RenderChildrenWithUpgradeTooltip } from './RenderChildrenWithUpgradeTooltip';
import { useAuth0User } from 'hooks/useAuth0User';

interface DisplayForRoleProps {
  roles: TokenUserRoles[];
  children: ReactNode;
}

export const DisplayForRole: React.FC<DisplayForRoleProps> = ({
  roles,
  children,
}) => {
  const { isVisible } = useWithUserPermissions(roles);
  const { roles: userRoles } = useAuth0User();

  if (
    isVisible &&
    roles.includes(TokenUserRoles.VIEWER) &&
    userRoles.includes(TokenUserRoles.VIEWER)
  ) {
    return (
      <RenderChildrenWithUpgradeTooltip>
        {children}
      </RenderChildrenWithUpgradeTooltip>
    );
  }

  if (isVisible) {
    return <>{children}</>;
  }

  return null;
};
