import { BasePagination, PaginableRequest } from 'api/common-schemas';
import {
  ContactGroupListItemType,
  DeviceType,
} from './ContactGroups/ContactGroups';
import { AlertConfigurationItemType } from './AlertConfiguration/AlertConfigurationTabel';

export enum TabsTypeEnum {
  group,
  devices,
}
// temporary
const data = [
  {
    id: 0,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 1,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MR42', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 8,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 2,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MR42', company_name: 'Meraki' },
    account: '72737243',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 3,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 4,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '62156059',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 5,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MR42', company_name: 'Meraki' },
    account: '62156059',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Not eligible' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 6,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Not eligible' as AlertConfigurationItemType['status'],
    time: null,
    'high-latency': null,
    recovery: null,
  },
  {
    id: 7,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 8,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MR42', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Disabled' as AlertConfigurationItemType['status'],
    time: null,
    'high-latency': null,
    recovery: null,
  },
  {
    id: 9,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '25386693',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 10,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Disabled' as AlertConfigurationItemType['status'],
    time: null,
    'high-latency': null,
    recovery: null,
  },
  {
    id: 11,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: null,
    'high-latency': null,
    recovery: null,
  },
];

export const getAlertConfigurationsMockData = (
  args: PaginableRequest,
): BasePagination<AlertConfigurationItemType> => {
  return {
    data: data.slice(0, args.page_size),
    metadata: {
      page: 1,
      page_size: args.page_size || 0,
      total_items: 12,
      total_pages: 1,
    },
  };
};

// temporary
const contactGroupData = [
  {
    name: 'Guardian Contacts',
    recipients: [
      { email: 'Efren.Prosacco24@gmail.com' },
      { email: 'Brenda24@hotmail.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 40,
    devices: [
      {
        name: 'c904c72b-d45a-49b01',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49b02',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49b03',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Incredible Metal Chips',
    recipients: [
      { email: 'Maximillia74@yahoo.com' },
      { email: 'Salma_Emard14@gmail.com' },
      { email: 'Clyde_Williamson@yahoo.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 0,
    devices: [],
  },
  {
    name: 'Gorgeous Concrete Towels',
    recipients: [
      { email: 'Darrel39@gmail.com' },
      { email: 'Claudine.Pollich45@yahoo.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 10,
    devices: [
      {
        name: 'c904c72b-d45a-49b0',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49b1',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49b2',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Generic Plastic Tuna',
    recipients: [
      { email: 'Chesley.Dicki81@yahoo.com' },
      { email: 'Lacey30@gmail.com' },
    ],
    status: 'inactive' as ContactGroupListItemType['status'],
    device_number: 80,
    devices: [
      {
        name: 'c904c72b-d45a-49b3',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49b4',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49b5',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Practical Wooden Mouse',
    recipients: [{ email: 'Florine.Lindgren@yahoo.com' }],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 10,
    devices: [
      {
        name: 'c904c72b-d45a-49b6',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49b7',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49b8',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Practical Wooden Hat',
    recipients: [
      { email: 'Guillermo_Marvin70@hotmail.com' },
      { email: 'Joannie76@yahoo.com' },
      { email: 'Maurine_Harber29@gmail.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 1,
    devices: [
      {
        name: 'c904c72b-d45a-49b9',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49ba',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49bb',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Handmade Steel Hat',
    recipients: [
      { email: 'Darrel.OKeefe@yahoo.com' },
      { email: 'Halle_Renner83@hotmail.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 30,
    devices: [
      {
        name: 'c904c72b-d45a-49bc',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49bd',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49be',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Intelligent Frozen Fish',
    recipients: [
      { email: 'Tyson2@hotmail.com' },
      { email: 'Vella.Gaylord94@yahoo.com' },
      { email: 'Esmeralda39@gmail.com' },
    ],
    status: 'inactive' as ContactGroupListItemType['status'],
    device_number: 30,
    devices: [
      {
        name: 'c904c72b-d45a-49bf',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49bg',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49bh',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Refined Fresh Shoes',
    recipients: [
      { email: 'Frederik.Lubowitz@yahoo.com' },
      { email: 'Lottie82@yahoo.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 999,
    devices: [
      {
        name: 'c904c72b-d45a-49bi',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49bj',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49b0k',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Lottie82@yahoo.com',
    recipients: [
      { email: 'Lionel.Schultz67@yahoo.com' },
      { email: 'Lionel.Schultz67@yahoo.com' },
      { email: 'Alberto_Cartwright@yahoo.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 100,
    devices: [
      {
        name: 'c904c72b-d45a-49bl',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49bm',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49bn',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Guardian Contacts',
    recipients: [
      { email: 'Efren.Prosacco24@gmail.com' },
      { email: 'Brenda24@hotmail.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 100,
    devices: [
      {
        name: 'c904c72b-d45a-49bo',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49bp',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49bq',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
  {
    name: 'Guardian Contacts',
    recipients: [
      { email: 'Efren.Prosacco24@gmail.com' },
      { email: 'Brenda24@hotmail.com' },
    ],
    status: 'active' as ContactGroupListItemType['status'],
    device_number: 100,
    devices: [
      {
        name: 'c904c72b-d45a-49bs',
        serial: 'dd0b5737-c314-425e',
        account: '85979050',
      },
      {
        name: 'c904c72b-d45a-49bt',
        serial: 'dd0b5737-c314-425e',
        account: '62156059',
      },
      {
        name: 'c904c72b-d45a-49bv',
        serial: 'dd0b5737-c314-425e',
        account: '55384651',
      },
    ],
  },
];

export const getContactGroupsMockData = (
  args: PaginableRequest,
): BasePagination<ContactGroupListItemType> => {
  return {
    data: contactGroupData.slice(0, args.page_size),
    metadata: {
      page: 1,
      page_size: args.page_size || 0,
      total_items: 12,
      total_pages: 1,
    },
  };
};

export const getDevicesMockData = (
  args: PaginableRequest,
): BasePagination<DeviceType> => {
  const flatContactGroupData = contactGroupData.flatMap((d) => d.devices);
  return {
    data: flatContactGroupData.slice(0, args.page_size),
    metadata: {
      page: 1,
      page_size: args.page_size || 0,
      total_items: flatContactGroupData.length,
      total_pages: Math.ceil(
        flatContactGroupData.length / (args.page_size || 1),
      ),
    },
  };
};
