import {
  GraniteButton,
  GraniteButtonProps,
} from 'components/V2/Button/GraniteButton';
import { FC } from 'react';

interface ReactiveGroupProps extends Pick<GraniteButtonProps, 'size'> {
  label: string;
}

const ReactiveGroup: FC<ReactiveGroupProps> = ({ label, ...props }) => {
  return (
    <GraniteButton variant="secondary" {...props}>
      {label}
    </GraniteButton>
  );
};

export default ReactiveGroup;
