import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import React, { useState } from 'react';
import GroupForm from './GroupForm';
import DevicesForm from './DevicesForm';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ContactGroupFormType, ContactGroupSchema } from './schema';
import { TabsTypeEnum } from '../../utils';

interface ContactGroupAddFormProps {
  close: () => void;
}

const ContactGroupAddForm: React.FC<ContactGroupAddFormProps> = ({ close }) => {
  const [activeTab, setActiveTab] = useState(TabsTypeEnum.group);

  const methods = useForm<ContactGroupFormType>({
    resolver: zodResolver(ContactGroupSchema),
    defaultValues: { name: '', recipients: [], devices: [] },
  });

  const onBack = () => setActiveTab(TabsTypeEnum.group);
  const onNext = () => setActiveTab(TabsTypeEnum.devices);

  const onSubmit = (data: ContactGroupFormType) => {
    console.log(data, 'tung');
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(
          activeTab === TabsTypeEnum.group ? onNext : onSubmit,
        )}
      >
        <div className="rounded-t-lg bg-background-base-surface-3 px-8 py-6 font-bold leading-9">
          <h1 className="text-2.5xl text-content-base-default">
            Add contact group
          </h1>
          <div className="mt-2 grid grid-cols-2 items-end gap-1 text-xs">
            <WizardStateBlock
              label="Enter group details"
              isActive={activeTab >= 0}
              isCompleted={activeTab > 0}
            />
            <WizardStateBlock
              label="Select devices"
              isActive={activeTab >= 1}
              isCompleted={activeTab >= 2}
              classNames="product-tour-noc-open-add-details"
            />
          </div>
        </div>
        <div className="p-8">
          {activeTab === TabsTypeEnum.group ? <GroupForm /> : null}
          {activeTab === TabsTypeEnum.devices ? <DevicesForm /> : null}
          <div className="mt-12 flex gap-5">
            {activeTab === TabsTypeEnum.group ? (
              <>
                <GraniteButton variant="secondary" size="large" onClick={close}>
                  Cancel
                </GraniteButton>
                <GraniteButton size="large" type="submit">
                  Next
                </GraniteButton>
              </>
            ) : null}
            {activeTab === TabsTypeEnum.devices ? (
              <>
                <GraniteButton
                  variant="secondary"
                  size="large"
                  onClick={onBack}
                >
                  Back
                </GraniteButton>
                <GraniteButton size="large" type="submit">
                  Add contact group{' '}
                </GraniteButton>
              </>
            ) : null}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ContactGroupAddForm;
