import { ContentLayout } from '../../layouts/ContentLayout/ContentLayout';
import { GraniteButton } from '../../components/V2/Button/GraniteButton';
import { ColumnDef } from '@tanstack/react-table';
import {
  QuoteIndexFilterSearchParams,
  QuoteIndexItem,
  QuoteStatusEnum,
} from '../../api/accessexpress/schemas';
import { ServerPaginatedTable } from '../../components/Table/ServerPaginatedTable';
import { useSortedTable } from '../../hooks/useSortedTable';
import { usePaginatedTable } from '../../hooks/usePaginatedTable';
import {
  fetchAccessStatisData,
  getQuoteIndex,
  refreshQuote,
} from '../../api/accessexpress/api';
import { Link } from 'react-router-dom';
import { FileTray } from 'react-ionicons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
} from '../../components/Table/Table.styles';
import SearchBar from '../../components/Table/SearchBar';
import { GraniteSelect, OptionType } from '../../components/Select/Select';
import { useFilterForTable } from '../../hooks/useFilterForTable';
import { Chip } from '../../components/Chip/Chip';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { useAuth0User } from 'hooks/useAuth0User';
import Filters from 'components/Filters';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SingleValue } from 'react-select';
import { filterByDate } from 'screens/Resources';
import { dateToUTCWithoutMS } from 'shared/util/dateToUtcStrWithoutMS';
import { endOfDay } from 'date-fns';
import { getCookie, setCookie } from 'shared/util/util';
import { DisplayForRole } from 'components/DisplayForRole/DisplayForRole';
import Loader from 'components/Loader';
import { useModal } from 'hooks/useModal';
import PricingRequestedDialog from 'screens/OpenQuote/AddProducts/PricingRequestedDialog';

const EmptyQuoteIndex = () => {
  return (
    <div className="col-span-full flex flex-col items-center gap-6 bg-background-base-surface-2 p-8">
      <FileTray
        color="rgb(var(--content-base-subdued))"
        width="48px"
        height="48px"
      />
      <p className="rounded font-bold leading-[22px] text-content-base-default">
        You haven&apos;t created any quotes yet.
      </p>
    </div>
  );
};

export const EmptySearchResults = () => {
  return (
    <div className="col-span-full flex flex-col items-center gap-6 bg-background-base-surface-2 p-8">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6785 8.46867C36.5629 7.58427 36.5629 6.15058 35.6785 5.26663C34.7941 4.38269 33.3609 4.38269 32.4765 5.26663L5.26618 32.4765C4.38223 33.3609 4.38223 34.7946 5.26618 35.6785C5.7086 36.1205 6.28779 36.3419 6.86742 36.3419C7.44706 36.3419 8.02624 36.1205 8.46867 35.6785L35.6785 8.46867ZM33.3278 30.7661C35.5889 27.9458 36.9442 24.3684 36.9442 20.4726C36.9442 17.5699 36.1911 14.8447 34.8729 12.4763L32.174 15.1752C32.9089 16.7919 33.3215 18.5842 33.3215 20.4726C33.3215 27.5577 27.5577 33.3219 20.4726 33.3219C18.5842 33.3219 16.7914 32.9089 15.1752 32.1744L12.4763 34.8729C14.8447 36.1911 17.5699 36.9447 20.4726 36.9447C24.3684 36.9447 27.9458 35.5889 30.7657 33.3278L41.6624 44.2245C42.0165 44.5782 42.4797 44.7548 42.9434 44.7548C43.4072 44.7548 43.8704 44.5782 44.2245 44.2245C44.9319 43.5167 44.9319 42.3702 44.2245 41.6628L33.3278 30.7661ZM6.07178 28.4688C4.75402 26.1005 4.00049 23.3753 4.00049 20.4726C4.00049 11.3755 11.3755 4.00049 20.4726 4.00049C23.3748 4.00049 26.1005 4.75402 28.4688 6.07178L25.7699 8.77071C24.1533 8.03621 22.3609 7.62322 20.4726 7.62322C13.3874 7.62322 7.62322 13.3874 7.62322 20.4726C7.62322 22.3609 8.03621 24.1533 8.77071 25.7699L6.07178 28.4688Z"
          fill="#94A3B8"
        />
      </svg>
      <p className="rounded font-bold leading-[22px] text-content-base-default">
        No results
      </p>
    </div>
  );
};

const QuoteItemStatus = ({ item }: { item: QuoteIndexItem }) => {
  const itemColor = useMemo(() => {
    switch (item.status) {
      case QuoteStatusEnum.enum.QUOTE_REQUESTED:
      case QuoteStatusEnum.enum['Quote requested']:
        return (
          <span className="min-h-[10px] min-w-[10px] rounded-full bg-status-info-default" />
        );
      case QuoteStatusEnum.enum.CHECKING_AVAILABILITY:
      case QuoteStatusEnum.Enum['Checking availability']:
        return (
          <span className="min-h-[10px] min-w-[10px] rounded-full bg-orange-300" />
        );
      case QuoteStatusEnum.enum.PRICING_AVAILABLE_WITH_ERRORS:
      case QuoteStatusEnum.enum.PRICING_AVAILABLE:
      case QuoteStatusEnum.enum['Pricing available']:
      case QuoteStatusEnum.enum['Service results ready']:
        return (
          <span className="min-h-[10px] min-w-[10px] rounded-full bg-[#F9CB34]" />
        );
      case QuoteStatusEnum.enum.PRICING_EXPIRED:
      case QuoteStatusEnum.enum['Pricing expired']:
        return (
          <span className="min-h-[10px] min-w-[10px] rounded-full bg-neutral-400" />
        );
      case QuoteStatusEnum.enum['Quote ready']:
      case QuoteStatusEnum.enum['Formal pricing available']:
        return (
          <span className="min-h-[10px] min-w-[10px] rounded-full bg-green-400" />
        );
      case QuoteStatusEnum.enum['Order submitted']:
        return (
          <span className="min-h-[10px] min-w-[10px] rounded-full bg-teal-400" />
        );
      case QuoteStatusEnum.enum['Quote expired']:
        return (
          <span className="min-h-[10px] min-w-[10px] rounded-full bg-red-500" />
        );
      case QuoteStatusEnum.enum.QE_CREATE_FAILED:
      case QuoteStatusEnum.enum['QE error']:
      case QuoteStatusEnum.enum['Service results expired']:
      case null:
        return (
          <span className="min-h-[10px] min-w-[10px] rounded-full bg-neutral-400" />
        );
    }
  }, [item.status]);

  const itemLabel = useMemo(() => {
    switch (item.status) {
      case QuoteStatusEnum.enum.QUOTE_REQUESTED:
      case QuoteStatusEnum.enum['Quote requested']:
        return <div>Quote requested</div>;
      case QuoteStatusEnum.enum.CHECKING_AVAILABILITY:
      case QuoteStatusEnum.Enum['Checking availability']:
        return <div>Checking availability</div>;
      case QuoteStatusEnum.enum.PRICING_AVAILABLE_WITH_ERRORS:
      case QuoteStatusEnum.enum.PRICING_AVAILABLE:
      case QuoteStatusEnum.enum['Service results ready']:
      case QuoteStatusEnum.enum['Pricing available']:
        // TODO: revert this once needed
        // if (item?.expiration_date) {
        //   return (
        //     <div>
        //       Service results ready
        //       <p className="text-xs text-content-base-subdued">
        //         {format(
        //           new Date(item?.expiration_date),
        //           "'Requires refresh on 'MM/dd/yyyy' at 'p",
        //         )}
        //       </p>
        //     </div>
        //   );
        // }
        return <>Service results ready</>;
      case QuoteStatusEnum.enum.PRICING_EXPIRED:
      case QuoteStatusEnum.enum['Pricing expired']:
        return <div>Pricing expired</div>;
      case QuoteStatusEnum.enum['Quote ready']:
      case QuoteStatusEnum.enum['Formal pricing available']:
        return <div>Quote ready</div>;
      case QuoteStatusEnum.enum.QE_CREATE_FAILED:
      case QuoteStatusEnum.enum['Order submitted']:
        return <div>Order submitted</div>;
      case QuoteStatusEnum.enum['QE error']:
      case QuoteStatusEnum.enum['Quote expired']:
        return <div>Quote expired</div>;
      case QuoteStatusEnum.enum['Service results expired']:
        return <div>Service results expired</div>;
      case null:
        return '';
    }
  }, [item.status]);

  return (
    <div className="flex items-center justify-center gap-2 text-sm">
      {itemColor}
      {itemLabel}
    </div>
  );
};

const QuoteItemAction = ({
  item,
  isQRAdmin,
  open,
}: {
  item: QuoteIndexItem;
  isQRAdmin: boolean;
  open: () => void;
}) => {
  const navigate = useNavigate();

  const refreshQuoteMutation = useMutation(refreshQuote, {
    onSuccess: () => {
      if (item.flow_type === 'simple') {
        navigate(`/access-express/open-quote/select-service-bundle/${item.id}`);
      } else if (item.flow_type === 'advanced') {
        open();
      }
    },
  });

  const onRefreshQuote = () => {
    if (refreshQuoteMutation.isLoading) return;
    refreshQuoteMutation.mutate(item.id);
  };

  if (isQRAdmin) {
    switch (item.status) {
      case QuoteStatusEnum.enum.QUOTE_REQUESTED:
      case QuoteStatusEnum.enum['Quote requested']:
        return (
          <GraniteButton
            variant="primary"
            size="small"
            onClick={() =>
              navigate('/upload-qr', {
                state: {
                  qe_quote_request_id: item.request_number?.toString(),
                },
              })
            }
          >
            Upload
          </GraniteButton>
        );
      case 'Quote ready':
      case 'Formal pricing available':
        return (
          <GraniteButton
            variant="secondary"
            size="small"
            onClick={() =>
              navigate('/upload-qr', {
                state: {
                  qe_quote_request_id: item.request_number?.toString(),
                  expiration_date: item.expiration_date,
                  formal_quote_filename: item.formal_quote_filename,
                },
              })
            }
          >
            Edit
          </GraniteButton>
        );
      case 'PRICING_EXPIRED':
      case 'QE_CREATE_FAILED':
      case 'Pricing expired':
      case 'QE error':
      case 'Order submitted':
      case 'Quote expired':
      case 'Service results expired':
      case QuoteStatusEnum.enum.CHECKING_AVAILABILITY:
      case QuoteStatusEnum.Enum['Checking availability']:
      case QuoteStatusEnum.enum.PRICING_AVAILABLE_WITH_ERRORS:
      case QuoteStatusEnum.enum.PRICING_AVAILABLE:
      case QuoteStatusEnum.enum['Pricing available']:
      case QuoteStatusEnum.enum['Service results ready']:
      case null:
        return <span className="text-sm text-content-base-subdued">N/A</span>;
    }
  } else {
    switch (item.status) {
      case QuoteStatusEnum.enum.QUOTE_REQUESTED:
      case QuoteStatusEnum.enum['Quote requested']:
        return (
          <GraniteButton
            variant="secondary"
            size="small"
            onClick={() => navigate(`/access-express/quote-details/${item.id}`)}
          >
            View details
          </GraniteButton>
        );
      case QuoteStatusEnum.enum.CHECKING_AVAILABILITY:
      case QuoteStatusEnum.Enum['Checking availability']:
        return (
          <span className="text-sm italic text-content-base-subdued">
            Pending results
          </span>
        );
      case QuoteStatusEnum.enum.PRICING_AVAILABLE_WITH_ERRORS:
      case QuoteStatusEnum.enum.PRICING_AVAILABLE:
      case QuoteStatusEnum.enum['Pricing available']:
      case QuoteStatusEnum.enum['Service results ready']:
        return (
          <DisplayForRole roles={[TokenUserRoles.VIEWER, TokenUserRoles.USER]}>
            <Link
              className="button primary small !bg-[#9796F3]
              hover:!bg-[#8685E2] hover:!text-white
               focus:!bg-[#7573D1] focus:!text-white focus:!ring-[#7573D1]
               active:!bg-[#6462C0] active:!text-white
               disabled:!bg-[#B2B1F5] disabled:!text-white
            "
              to={`/access-express/open-quote/select-service-bundle/${item.id}`}
            >
              Select pricing
            </Link>
          </DisplayForRole>
        );
      case QuoteStatusEnum.enum['Quote expired']:
      case QuoteStatusEnum.enum.PRICING_EXPIRED:
      case QuoteStatusEnum.enum['Pricing expired']:
        return (
          <DisplayForRole roles={[TokenUserRoles.VIEWER, TokenUserRoles.USER]}>
            <GraniteButton
              variant="secondary"
              size="small"
              onClick={onRefreshQuote}
              disabled={item.status === 'Quote expired'}
            >
              Refresh{' '}
              {refreshQuoteMutation.isLoading && (
                <Loader animationClassname="!w-3 !h-3" />
              )}
            </GraniteButton>
          </DisplayForRole>
        );
      case QuoteStatusEnum.enum['Order submitted']:
        return (
          <GraniteButton
            variant="secondary"
            size="small"
            onClick={() =>
              navigate(`/access-express/order-submitted/${item.id}`)
            }
          >
            View order
          </GraniteButton>
        );
      case QuoteStatusEnum.enum['Formal pricing available']:
      case QuoteStatusEnum.enum['Quote ready']:
        return (
          <DisplayForRole roles={[TokenUserRoles.VIEWER, TokenUserRoles.USER]}>
            <GraniteButton
              variant="primary"
              size="small"
              onClick={() => {
                navigate(
                  `/access-express/open-quote/select-services/${item.id}`,
                );
              }}
            >
              Order services
            </GraniteButton>
          </DisplayForRole>
        );
      case QuoteStatusEnum.enum.QE_CREATE_FAILED:
      case QuoteStatusEnum.enum['QE error']:
      case QuoteStatusEnum.enum['Service results expired']:
      case null:
        return <></>;
    }
  }
};

const quoteIndexFilterOptions = [
  {
    label: 'Quote requested',
    value: QuoteStatusEnum.enum['Quote requested'],
  },
  {
    label: 'Checking availability',
    value: QuoteStatusEnum.enum['Checking availability'],
  },
  {
    label: 'Pricing expired',
    value: QuoteStatusEnum.enum['Pricing expired'],
  },
  {
    label: 'QE error',
    value: QuoteStatusEnum.enum['QE error'],
  },
  {
    label: 'Quote ready',
    value: QuoteStatusEnum.enum['Quote ready'],
  },
  {
    label: 'Quote expired',
    value: QuoteStatusEnum.enum['Quote expired'],
  },
  {
    label: 'Order submitted',
    value: QuoteStatusEnum.enum['Order submitted'],
  },
  {
    label: 'Service results ready',
    value: QuoteStatusEnum.enum['Service results ready'],
  },
];

const getQuoteIndexTableDef = (
  roles: TokenUserRoles[],
  open: () => void,
): ColumnDef<QuoteIndexItem>[] => {
  const columns: ColumnDef<QuoteIndexItem>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Requested by',
      accessorKey: 'requester',
    },
    {
      header: 'Request #',
      accessorKey: 'request_number',
      cell: (props) => (
        <span className="h-6 rounded-lg bg-background-base-surface-3 px-2 text-xs font-bold leading-6">
          #{props.getValue() as string}
        </span>
      ),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (props) => <QuoteItemStatus item={props.row.original} />,
    },
    {
      header: 'Last updated',
      accessorKey: 'last_update',
      cell: ({ getValue }) => {
        const date = getValue() as string | undefined;

        if (!date) {
          return <span className="text-sm text-content-base-subdued">N/A</span>;
        }

        const formattedDate = format(
          typeof date === 'string' ? new Date(date) : date,
          'MM/dd/yyyy',
        );
        return formattedDate;
      },
    },
    {
      header: 'Date submitted',
      accessorKey: 'date_submitted',
      cell: ({ getValue, row }) => {
        const date = (getValue() as string) + 'Z';

        if (!date) {
          return <span className="text-sm text-content-base-subdued">N/A</span>;
        }

        const utcDate = new Date(date);

        const localDateTime = utcDate.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        const submitter = row.original.submitter ?? '';

        return isNaN(utcDate.getTime()) ? (
          <span className="text-sm text-content-base-subdued">
            Invalid Date
          </span>
        ) : (
          <StackedCell>
            <StackedCellMainContent>{localDateTime}</StackedCellMainContent>
            <StackedCellSubtitle>{submitter}</StackedCellSubtitle>
          </StackedCell>
        );
      },
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: (props) => (
        <QuoteItemAction
          item={props.row.original}
          isQRAdmin={roles.includes(TokenUserRoles.QR_ADMIN)}
          open={open}
        />
      ),
    },
  ];

  if (roles.includes(TokenUserRoles.QR_ADMIN)) {
    columns.unshift({
      header: 'Company',
      id: 'customer_name',
      cell: (props) => (
        <span className="text-[14px] text-content-base-default ">
          {props.row.original.customer_name}
        </span>
      ),
    });
  }

  return columns;
};

export const QuoteIndexPage = () => {
  const [search, setSearch] = useState('');
  const { roles, isQRAdminRole } = useAuth0User();
  const [pageSize, setPageSize] = useState<number>();
  const navigate = useNavigate();
  const [dateFilter, setDateFilter] = useState<SingleValue<OptionType>>(null);
  const { data: staticData, isLoading } = useQuery(
    ['static-data'],
    () => fetchAccessStatisData(),
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'last_update', desc: true }],
  });

  const {
    queryParamFilter: statusFilter,
    clearFilter: clearStatusFilter,
    ...statusFilterProps
  } = useFilterForTable({ queryParamKey: 'status' });

  const {
    queryParamFilter: requesterFilter,
    clearFilter: clearRequesterFilter,
    ...requesterFilterProps
  } = useFilterForTable({ queryParamKey: 'requester' });

  const getQuoteIndexFn = useCallback(
    (args?: QuoteIndexFilterSearchParams) => {
      return getQuoteIndex({
        ...statusFilter,
        ...(search ? { search } : {}),
        ...(dateFilter
          ? {
              start_date: dateFilter.value,
              end_date: dateToUTCWithoutMS(endOfDay(new Date())),
            }
          : {}),
        ...sortingQueryParams,
        ...args,
        ...requesterFilter,
      });
    },
    [dateFilter, search, sortingQueryParams, statusFilter, requesterFilter],
  );

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    getQuoteIndexFn,
    {
      search,
      statusFilter,
      sortingQueryParams,
    },
    [
      'quote-index-table',
      sortingQueryParams,
      search,
      statusFilter,
      dateFilter,
      requesterFilter,
    ],
    { refetchOnMount: true },
  );

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'AccessExpress',
      onClick: () => navigate('/access-express'),
    },
    {
      label: 'Quote requests',
    },
  ];

  const clearAllFilters = useCallback(() => {
    clearStatusFilter();
    setDateFilter(null);
    clearRequesterFilter();
  }, [clearRequesterFilter, clearStatusFilter]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRowClick = (row: any) => {
    const item = row.original;
    switch (item.status) {
      case QuoteStatusEnum.enum.QUOTE_REQUESTED:
        return;
      case QuoteStatusEnum.enum.CHECKING_AVAILABILITY:
        return;
      case QuoteStatusEnum.enum.PRICING_AVAILABLE_WITH_ERRORS:
      case QuoteStatusEnum.enum.PRICING_AVAILABLE:
        return navigate(`open-quote/${item.id}`);
      case QuoteStatusEnum.enum.PRICING_EXPIRED:
        return navigate(
          `/access-express/open-quote/resubmit-open-quote/${item.id}`,
        );
    }
  };
  useEffect(() => {
    const savedSize = getCookie('paginationSizeAccessExpress');
    if (savedSize) {
      setPageSize(parseInt(savedSize, 10));
    }
  }, []);

  const paginationChanged = (page: number) => {
    setPageSize(page);
    setCookie('paginationSizeAccessExpress', page.toString(), 365);
  };

  const { open, ...modalProps } = useModal();
  const queryClient = useQueryClient();

  const onPricingRequestedClose = () => {
    modalProps.close();
    queryClient.refetchQueries([
      'quote-index-table',
      sortingQueryParams,
      search,
      statusFilter,
      dateFilter,
      requesterFilter,
    ]);
  };

  return (
    <ContentLayout>
      <div className="mb-6 flex justify-between">
        <PageTitle
          className="w-full"
          title="AccessExpress"
          breadcrumbs={breadcrumbs}
        >
          <div className="my-auto">
            {roles.includes(TokenUserRoles.QR_ADMIN) ? (
              <GraniteButton
                size="large"
                variant="primary"
                onClick={() => navigate('/upload-qr')}
              >
                Upload QR
              </GraniteButton>
            ) : (
              <DisplayForRole
                roles={[TokenUserRoles.VIEWER, TokenUserRoles.USER]}
              >
                <Link className="button primary large" to="/access-express">
                  Open quote request
                </Link>
              </DisplayForRole>
            )}
          </div>
        </PageTitle>
      </div>
      <h2 className="mb-8 text-[28px] font-bold leading-9 text-content-base-default">
        Your quote requests
      </h2>
      <div className="mb-4 flex w-full flex-wrap items-start justify-between gap-4">
        <div className="w-full sm:flex-1">
          <SearchBar
            placeholder="Search by name or request #"
            onSearch={setSearch}
            onQueryClear={() => {
              setSearch('');
            }}
            clearAllValues={search === ''}
          />
        </div>

        <Filters
          clearFilters={clearAllFilters}
          clearFilterClassName="col-span-1"
        >
          <GraniteSelect
            isMulti
            className="col-span-1"
            placeholder="Filter by status"
            options={quoteIndexFilterOptions}
            controlShouldRenderValue={false}
            isSearchable={false}
            {...statusFilterProps}
          />
          <GraniteSelect
            isMulti
            className="col-span-1"
            placeholder={`Filter by ${isQRAdminRole ? 'company' : 'requester'}`}
            options={
              !isLoading && staticData && staticData.requester_options
                ? staticData.requester_options.map((option) => ({
                    label: option,
                    value: option,
                  }))
                : []
            }
            controlShouldRenderValue={false}
            isSearchable={false}
            {...requesterFilterProps}
          />
          <GraniteSelect
            options={filterByDate}
            isClearable={false}
            isSearchable={false}
            className="col-span-1"
            placeholder="Filter by date requested"
            controlShouldRenderValue={false}
            onChange={setDateFilter}
            value={dateFilter}
          />
        </Filters>
      </div>
      <div className="gap mb-12 flex gap-4">
        {statusFilterProps.value.map((filter) => (
          <Chip
            key={filter.value}
            label={filter.label}
            className="mt-4"
            onDelete={() => {
              statusFilterProps.onChange(
                statusFilterProps.value.filter((v) => v.value !== filter.value),
              );
            }}
          />
        ))}
        {requesterFilterProps.value.map((filter) => (
          <Chip
            key={filter.value}
            label={filter.label}
            className="mt-4"
            onDelete={() => {
              requesterFilterProps.onChange(
                requesterFilterProps.value.filter(
                  (v) => v.value !== filter.value,
                ),
              );
            }}
          />
        ))}
        {dateFilter?.value && (
          <Chip label={dateFilter.label} onDelete={() => setDateFilter(null)} />
        )}
      </div>
      <ServerPaginatedTable
        data={tablePageData as QuoteIndexItem[]}
        columns={getQuoteIndexTableDef(roles, open)}
        title="quote index table"
        sortingState={sortingState}
        onSortingChange={onSortingChange}
        showHeadersWhenEmpty={false}
        emptyDataElement={
          search ||
          statusFilterProps.value.length > 0 ||
          requesterFilterProps.value.length > 0 ||
          dateFilter ? (
            <EmptySearchResults />
          ) : (
            <EmptyQuoteIndex />
          )
        }
        handleRowClick={handleRowClick}
        paginationChanged={paginationChanged}
        paginationSizeStored={pageSize}
        {...paginatedTableProps}
      />
      <PricingRequestedDialog {...modalProps} close={onPricingRequestedClose} />
    </ContentLayout>
  );
};
