import { LetUsHelpLayout } from '../BaseComponents/LetUsHelpLayout';
import { LetUsHelpBaseForm } from '../BaseComponents/LetUsHelpBaseForm';
import FormSection from '../../../components/FormSection';
import { GraniteInput } from '../../../components/V2/Input/GraniteInput';
import {
  Dispatch,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { ReviewTicketForm, ReviewTicketFormSchema } from './schemas';
import Divider from '../../../components/Divider';
import { GraniteButton } from '../../../components/V2/Button/GraniteButton';
import { Add, CloseCircle } from 'react-ionicons';
import Checkbox from '../../../components/Checkbox';
import GraniteTextArea from '../../../components/TextArea/TextArea';
import { StandardTools } from '../../../components/StandardTools/StandardTools';
import { GraniteLabel } from '../../../components/V2/Label/GraniteLabel';
import { isToday } from 'date-fns';
import RadioButton from '../../../components/RadioButton';
import {
  RangeTimePicker,
  TimePicker,
} from '../../../components/TimePicker/TimePicker';
import { SearchAddressBar } from '../../../components/SearchAddressBar/SearchAddressBar';
import { FileUpload } from '../../../components/V2/FileUpload/FileUpload';
import { Surface } from '../BaseComponents/Surface';
import { PriorityBadge } from '../../../components/PriorityBadge/PriorityBadge';

import { EstimatedCost } from '../../../components/EstimatedCost/EstimatedCost';
import { getInternalTicketTypeAndProductNamesFromQuestionnaire } from '../utils/GetTypeOfTicketFromQuestionnaire';
import {
  labelForTicketType,
  labelForTicketTypeBoard,
  netNameForProductType,
} from '../BaseComponents/schemas';
import { Navigate, useNavigate } from 'react-router-dom';
import { PriorityInfo } from '../BaseComponents/PriorityDescriptions';
import { useModal } from '../../../hooks/useModal';
import { Template, Ticket } from '../../../api/techexpress/schema';
import { ReviewTicketConfirmationDialog } from './ReviewTicketConfirmationDialog';
import { useMutation, useQuery } from 'react-query';
import {
  createTicket,
  deleteDraft,
  getEstimatedCost,
  templateUse,
} from '../../../api/techexpress/api';
import { reviewTicketFormToRequest } from './reviewTicketFormToRequest';
import {
  useLetUsHelpContext,
  WizardAction,
} from '../WizardProvider/WizardReducer';
import {
  GetStartedQuestionnaireForm,
  LocationsArraySchema,
  LocationsSchema,
} from '../GetStarted/schemas';
import { preventSubmitOnEnter } from '../../../shared/util/preventSubmitOnEnter';
import PhoneNumberInput from '../../../components/V2/Input/PhoneNumber';
import { getTimeZoneAbbreviationFromZipCode } from 'shared/util/util';
import {
  getDispatchPriority,
  useDispatchPriority,
  zonedAccessTimeToUtc,
} from '../../../shared/hooks/useDispatchPriority';
import showToast from '../../../components/Toast/Toast';
import { lookup } from 'zipcode-to-timezone';
import { isAfterCutoffTime } from '../../../shared/util/getTodaysCutoffTime';
import { DispatchDateSelectorField } from '../BaseComponents/DispatchDateSelectorField';
import { getDuplicateTickets } from '../../../api/techexpress/getDuplicateTickets';
import { DuplicateTicketWarningDialog } from '../../../components/DuplicateTicketWarningDialog/DuplicateTicketWarningDialog';
import { NonStandardToolsFieldTextField } from '../BaseComponents/NonStandardToolsField';
import { DeepPartial } from '../../../types/common-types';
import { TicketDetailFormSchemas } from '../TicketDetails/schemas';
import { dispatchReviewFormFromTicketDetailUnionForm } from '../utils/dispatchReviewFormFromTicketDetailUnionForm';
import { EstimatedCostResponse } from '../../../api/techexpress/schemas/EstimatedCostSchemas';
import { useAuth0User } from 'hooks/useAuth0User';
import AddEmailRecipient from '../utils/AddEmailRecipient';
import { useFetchEstimatedCosts } from './useFetchEstimatedCosts';
import { LocationList } from '../GetStarted/LocationsList';
import Accordion from 'components/Accordion';
import { BulkModalLocations } from '../GetStarted/BulkModalLocations';
import { getTicketReviewMockData } from 'mocks/tour';
import { useProductTourContext } from 'context/ProductTourContext';
import { ReviewSkeleton } from './ReviewSkeleton';
import createGetStartedReviewResolver from '../utils/createGetStartedReviewResolver';

const MAX_CONTACTS_LENGTH = 2;

export interface PriorityItem {
  priority: 'P1' | 'P2' | 'P3';
  index: number;
}

export const accessTimeDummy = {
  scheduling_type: 'Hard Start',
};

export const dummySiteData = {
  name: '12345 - 123 .',
  address_line_1: '1234  N.E.',
  parent_macnum: '123456',
  parent_name: '1234',
  city: '1234',
  state: '123',
  zip: '123',
  id: 12345,
};

type PartialReviewForm = DeepPartial<
  Omit<ReviewTicketForm, 'accessTime' | 'dispatchDate'>
>;

interface ReviewProps {
  draftId?: string;
  dispatch: Dispatch<WizardAction>;
  questionnaire: GetStartedQuestionnaireForm;
  reviewFormDefaultValue?: PartialReviewForm;
  template?: Template;
}

export interface EstimatedCostProps {
  [priority: string]: EstimatedCostResponse[];
}
const Review = ({
  draftId,
  dispatch,
  questionnaire,
  reviewFormDefaultValue,
  template,
}: ReviewProps) => {
  const navigate = useNavigate();

  const fallbackPriority = 'P1'; // Use P1 when priority can't be calculated which would show a form error as well
  const fallbackTimezone = 'America/New_York';

  const { email: ticketCreatorEmail } = useAuth0User();
  const isFirstRender = useRef<boolean>(true);
  const defaultValues = {
    type: questionnaire.type,
    nonStandardTools: { isNeeded: false },
    attachments: [],
    po_number: '',
    dispatchDate: questionnaire.dispatchDate,
    accessTime: questionnaire.accessTime,
    isSendNotificationsViaEmailEnabled: true,
    site: questionnaire.site,
    content: {
      ...questionnaire.content,
      ...(questionnaire.content.type === 'single' && {
        localContact: [{ name: '', phoneNumber: '' }],
      }),
    },
    ...reviewFormDefaultValue,
  };

  const mockData = useMemo(() => getTicketReviewMockData(), []);
  const { running } = useProductTourContext();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<ReviewTicketForm>({
    resolver: createGetStartedReviewResolver(ReviewTicketFormSchema),
    //@ts-expect-error asdasd
    defaultValues: running ? mockData : defaultValues,
  });

  const ticketTypeProductPair =
    getInternalTicketTypeAndProductNamesFromQuestionnaire(questionnaire);
  const { ticketType, product } = ticketTypeProductPair;
  const [accessTime, dispatchDate] = watch(['accessTime', 'dispatchDate']);

  const [priority] = useDispatchPriority({
    dispatchDate,
    accessTime,
    timezone:
      lookup(reviewFormDefaultValue?.site?.zip as string) ?? fallbackTimezone,
  });

  const { data: estimatedCost } = useQuery(
    [priority, ticketType, product, 'estimate'],
    () => {
      return getEstimatedCost({
        board_name: netNameForProductType(product),
        sub_type_name: priority ?? fallbackPriority,
        type_name: labelForTicketType(ticketType),
      });
    },
  );

  const { name: ticketCreatorName } = useAuth0User();
  const [priorities, setPriorities] = useState<PriorityItem[]>([]);

  const [estimatedCosts, setEstimatedCosts] = useState<EstimatedCostProps>({});

  useEffect(() => {
    setValue('priority', priority ?? fallbackPriority);
  }, [priority, setValue]);

  useFetchEstimatedCosts({
    priorities,
    ticketType,
    product,
    setEstimatedCosts,
  });

  // RHF has a bug where if a component has a default value and is wrapped in a controller,
  // if the component sets undefined as its value (eg, to indicate empty) the Controller
  // will still report the value to be the defaultValue, but internally the form has set the value to
  // undefined, breaking consistency.
  // This is the case of the SearchAddress component when a user tries to clear an address.
  // Set the SearchBar address state from here rather than in a controller and update the form with `setValue`
  const site = watch('site');

  const type = watch('type');

  const locations = watch('content.locations');

  const scopeOfWork = watch('scopeOfWork');

  const priorTicket = watch('prior_ticket');

  useEffect(() => {
    if (!priorTicket) setValue('customer_preferred_tech', undefined);
  }, [priorTicket, setValue]);

  useEffect(() => {
    if (isFirstRender.current && type === 'multi') {
      //@ts-expect-error add dummy data
      setValue('accessTime', accessTimeDummy);
      setValue('site', dummySiteData);
      isFirstRender.current = false;
    }
  }, [isFirstRender, setValue, type]);

  useEffect(() => {
    if (type === 'multi') {
      if (locations.length > 0) {
        const newPriorities = locations.map((location, index) => {
          return {
            priority: location.priority.value as 'P1' | 'P2' | 'P3',
            index: index,
          };
        });
        setPriorities(newPriorities);
      }
    }
  }, [locations, type]);

  const {
    fields: localContactFields,
    append: localContactAppend,
    remove: localContactRemove,
  } = useFieldArray({
    control,
    name: 'content.localContact',
  });

  const {
    fields: notificationRecipients,
    append: appendNotificationRecipient,
    remove: removeNotificationRecipient,
  } = useFieldArray({
    control,
    name: 'recipients',
  });

  const { openWithProps, dynamicProps, ...modalProps } = useModal<{
    formData: ReviewTicketForm;
    estimatedCost?: EstimatedCostResponse;
  }>();

  const { openWithProps: openDuplicateDialog, ...duplicateModalProps } =
    useModal<Ticket[]>();

  const { open, ...bulkModalProps } = useModal<LocationsArraySchema>();

  const createTicketMutation = useMutation(
    ({ newTicket }: { newTicket: FormData }) => createTicket(newTicket),
    {
      onSettled: () => {
        if (draftId) deleteDraft(draftId);
        navigate('/tech-express');
      },
      onSuccess: (data) => {
        if (template) {
          templateUse(template.id).catch(console.log);
        }
        showToast.confirmation(
          data.length === 1
            ? {
                title: `Ticket has been successfully created!`,
                message: `Ticket #${data[0].id}`,
              }
            : {
                title: `Tickets have been successfully created!`,
                message: `${data.length} tickets`,
              },
        );
        data
          .flatMap((d) => d.attachments)
          ?.filter((a) => a?.status === 'failure')
          .forEach((a) =>
            showToast.error({
              message: `The attachment '${a?.filename}' could not be uploaded.`,
            }),
          );
        dispatch({ type: 'ResetForms' });
      },
    },
  );

  const userFacingTicketType = labelForTicketType(ticketType);
  const userFacingProduct = netNameForProductType(product);

  const onSubmit = async (formData: ReviewTicketForm) => {
    if (formData.type === 'single') {
      const duplicates = await getDuplicateTickets({
        site: formData.site.id,
        dispatchDate: formData.dispatchDate,
      });
      if (duplicates.length > 0) {
        openDuplicateDialog(duplicates);
      } else {
        openWithProps({ formData, estimatedCost: estimatedCost });
      }
    } else {
      createTicketMutation.mutate({
        newTicket: reviewTicketFormToRequest(
          formData,
          userFacingTicketType,
          userFacingProduct,
          fallbackPriority,
          ticketCreatorEmail ?? '', // Note this shouldn't be null if inside the Auth0 context
          ticketCreatorName,
        ),
      });
    }
  };

  const onError = (error: unknown) => {
    console.log('onSubmitError called', error);
  };

  const onDialogConfirmation = (data: ReviewTicketForm) => {
    if (isAfterCutoffTime() && isToday(data.dispatchDate)) {
      trigger('dispatchDate');
      showToast.error({
        message: 'Unable to schedule dispatch for today at this time.',
      });
      return;
    }
    const currentPriority = data.priority;
    try {
      const siteTimezone = lookup(data.site.zip) ?? fallbackTimezone;
      const recalculatedPriority = getDispatchPriority(
        data?.dispatchDate,
        zonedAccessTimeToUtc(data.accessTime, siteTimezone),
        siteTimezone,
        new Date(),
      );
      if (currentPriority !== recalculatedPriority) {
        trigger('accessTime.start_date');
        showToast.error({
          message: 'Priority has changed, review the changes.',
        });
      } else {
        createTicketMutation.mutate({
          newTicket: reviewTicketFormToRequest(
            data,
            userFacingTicketType,
            userFacingProduct,
            currentPriority || fallbackPriority,
            ticketCreatorEmail ?? '', // Note this shouldn't be null if inside the Auth0 context
            ticketCreatorName,
          ),
        });
      }
    } catch (e) {
      showToast.error({ message: 'Dispatch date is no longer valid' });
      trigger('accessTime.start_date');
    }
  };

  const timeZoneAbbreviation = getTimeZoneAbbreviationFromZipCode(
    site?.zip || '',
  );

  const isSendNotificationsViaEmailEnabled = watch(
    'isSendNotificationsViaEmailEnabled',
  );
  watch((formData) => {
    dispatch({
      type: 'UpdateReview',
      reviewForm: formData,
    });
  });

  const memoizedTotal = useMemo(() => {
    return Object.keys(estimatedCosts).reduce((acc, priority) => {
      const priorityTotal = estimatedCosts[priority].reduce(
        (sum: number, item: EstimatedCostResponse) => sum + item.total,
        0,
      );
      return acc + priorityTotal;
    }, 0);
  }, [estimatedCosts]);

  const uploadLocations = async (data: LocationsSchema) => {
    setValue('type', 'multi');
    setValue('content.locations', data.locations);
    setValue('content.type', 'multi');
    await trigger('content.locations');
  };

  const summaryEstimatedCost = useMemo(() => {
    return Object.entries(estimatedCosts).map(([key, value]) => {
      const totals = value.reduce(
        (acc, cur) => ({
          rate_per_hour: acc.rate_per_hour + cur.rate_per_hour,
          hours: acc.hours + cur.hours,
          dispatch_fee_dollars:
            acc.dispatch_fee_dollars + cur.dispatch_fee_dollars,
          misc_fee_dollars: acc.misc_fee_dollars + cur.misc_fee_dollars,
          total: acc.total + cur.total,
        }),
        {
          rate_per_hour: 0,
          hours: 0,
          dispatch_fee_dollars: 0,
          misc_fee_dollars: 0,
          total: 0,
        },
      );

      return {
        priority: key,
        ...totals,
      };
    });
  }, [estimatedCosts]);

  return (
    <LetUsHelpLayout>
      <LetUsHelpBaseForm
        className="gap-16"
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={preventSubmitOnEnter}
        id="ticket-review-form"
      >
        <FormSection title="Basic information" gridClassName="grid-cols-2">
          <Surface className="col-span-full grid grid-cols-2 gap-8">
            <div>
              <div className="text-base font-bold leading-6 text-content-base-subdued">
                Ticket type
              </div>
              <div className="text-xl font-bold text-content-base-default">
                {labelForTicketTypeBoard(ticketTypeProductPair)}
              </div>
            </div>
          </Surface>

          <GraniteInput
            className="grid-cols-1"
            error={errors.customer_ticket_number?.message}
            label="Customer ticket # (optional)"
            subtitle="Multiple entries should be separated with commas"
            {...register('customer_ticket_number')}
          />
          <GraniteInput
            className="grid-cols-1"
            error={errors.prior_ticket?.message}
            label="Prior ticket # (optional)"
            subtitle="Tickets that share info pertaining to this dispatch"
            {...register('prior_ticket')}
          />
          {priorTicket && (
            <Controller
              name="customer_preferred_tech"
              control={control}
              render={({ field: { onChange, value, ...field } }) => {
                if (value === undefined) onChange(true);
                return (
                  <Checkbox
                    className="col-start-2 grid-cols-1"
                    label="Request the same technician from this ticket"
                    checked={value}
                    onChange={onChange}
                    {...field}
                  />
                );
              }}
            />
          )}
        </FormSection>
        {type === 'single' && questionnaire.content.type === 'single' && (
          <>
            <Divider />
            <FormSection
              title="Local contact details"
              subtitle="A local contact should be someone on site that can provide access to the dispatch location."
            >
              {localContactFields.map((field, index) => (
                <Fragment key={field.id}>
                  <h3 className="col-span-full text-xl font-bold text-content-base-subdued">
                    Local contact {index + 1}
                  </h3>
                  <div className="col-span-full grid grid-cols-2 items-start gap-x-4 gap-y-6">
                    <GraniteInput
                      error={
                        errors?.content &&
                        'localContact' in errors.content &&
                        Array.isArray(errors.content.localContact)
                          ? errors.content?.localContact?.[index]?.name?.message
                          : ''
                      }
                      label="Name"
                      aria-label={`Local contact name ${index + 1}`}
                      {...register(`content.localContact.${index}.name`)}
                    />
                    <GraniteInput
                      error={
                        errors?.content &&
                        'localContact' in errors.content &&
                        Array.isArray(errors.content.localContact)
                          ? errors.content?.localContact?.[index]?.email
                              ?.message
                          : ''
                      }
                      label="Email (optional)"
                      aria-label={`Local contact email ${index + 1}`}
                      {...register(`content.localContact.${index}.email`)}
                    />
                    <PhoneNumberInput
                      name={`content.localContact.${index}.phoneNumber`}
                      label="Phone number"
                      error={
                        errors?.content &&
                        'localContact' in errors.content &&
                        Array.isArray(errors.content.localContact)
                          ? errors.content?.localContact?.[index]?.phoneNumber
                              ?.message
                          : ''
                      }
                      aria-label={`Local contact phone number ${index + 1}`}
                      control={control}
                    />
                    <GraniteInput
                      error={
                        errors?.content &&
                        'localContact' in errors.content &&
                        Array.isArray(errors.content.localContact)
                          ? errors.content?.localContact?.[index]?.extension
                              ?.message
                          : ''
                      }
                      {...register(`content.localContact.${index}.extension`)}
                      label="Extension (optional)"
                      aria-label={`Local contact extension ${index + 1}`}
                    />
                  </div>
                  {index > 0 && index < MAX_CONTACTS_LENGTH ? (
                    <div>
                      <GraniteButton
                        variant="secondary"
                        onClick={() => localContactRemove(index)}
                      >
                        Delete contact {index + 1}
                      </GraniteButton>
                    </div>
                  ) : null}
                </Fragment>
              ))}
              {localContactFields.length < MAX_CONTACTS_LENGTH ? (
                <div>
                  <GraniteButton
                    variant="secondary"
                    className="col-span-full mt-2"
                    onClick={() =>
                      localContactAppend({ name: '', phoneNumber: '' })
                    }
                  >
                    Add another contact
                    <Add
                      color="inherit"
                      width="16px"
                      height="16px"
                      cssClasses="ml-2"
                    />
                  </GraniteButton>
                </div>
              ) : null}
            </FormSection>
          </>
        )}
        {type === 'multi' && (
          <FormSection title="Addresses">
            <LocationList hasPriority={true} locations={locations} />
            <div className="flex items-center justify-start gap-4">
              <GraniteButton variant="secondary" onClick={open}>
                Edit
              </GraniteButton>
            </div>
          </FormSection>
        )}
        <Divider />
        <FormSection title="Notifications">
          <div className=" flex flex-col gap-y-4">
            <div className="col-span-full">
              <h3 className="mb-2 text-base font-bold text-content-base-subdued">
                Would you like to receive notifications for this ticket?
              </h3>
              <Controller
                name="isSendNotificationsViaEmailEnabled"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <Checkbox
                    label="Send me updates via email"
                    className="col-span-full"
                    checked={value}
                    onChange={onChange}
                    {...field}
                  />
                )}
              />
            </div>
            {isSendNotificationsViaEmailEnabled && (
              <div className="flex flex-wrap">
                <div className="flex h-8 items-center rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-2">
                  <span className="fill-content-base-subdued pr-1 text-content-base-default">
                    {ticketCreatorEmail}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-4">
            <AddEmailRecipient appendFunc={appendNotificationRecipient} />
            <div className="col-span-full flex">
              {(notificationRecipients?.length ?? 0) > 0 && (
                <div className="flex flex-wrap gap-2">
                  {notificationRecipients?.map((field, i) => (
                    <div
                      key={i}
                      className="flex h-8 items-center rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-2"
                    >
                      <span className="fill-content-base-subdued pr-1 text-content-base-default">
                        {field.email}
                      </span>
                      <button
                        className="fill-content-base-subdued"
                        type="button"
                      >
                        <CloseCircle
                          width="16px"
                          height="16px"
                          color="inherit"
                          onClick={() => removeNotificationRecipient(i)}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </FormSection>
        {type === 'single' && (
          <>
            <Divider />
            <FormSection
              title="Dispatch date & time"
              gridClassName="grid-cols-2 w-full"
            >
              <GraniteLabel className="col-span-1" label="Date">
                <DispatchDateSelectorField
                  name="dispatchDate"
                  control={control}
                />
              </GraniteLabel>
              <Controller
                name="accessTime"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <GraniteLabel label="Access time" className="col-start-1">
                    <RadioButton
                      options={[
                        {
                          value: 'Hard Start',
                          label: 'Arrival at specified time',
                        },
                        {
                          value: 'Requested Window',
                          label: 'Arrival during dispatch window',
                        },
                      ]}
                      selectedValue={value.scheduling_type}
                      onChange={(type) =>
                        onChange({ ...value, scheduling_type: type })
                      }
                      ref={ref}
                      className="text-base"
                    />
                  </GraniteLabel>
                )}
              />

              <div className="col-span-1 col-start-1">
                <Controller
                  name="accessTime"
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    value.scheduling_type === 'Hard Start' ? (
                      <GraniteLabel
                        label="Specified time"
                        subtitle="Time will be automatically converted to the local timezone of the location"
                      >
                        <TimePicker
                          onChange={(d) =>
                            onChange({ ...value, start_date: d })
                          }
                          value={value.start_date}
                          error={errors.accessTime?.start_date?.message}
                          timeZoneAbbreviation={timeZoneAbbreviation}
                        />
                      </GraniteLabel>
                    ) : (
                      <GraniteLabel
                        label="Dispatch window"
                        subtitle="Time will be automatically converted to the local timezone of the location"
                      >
                        <RangeTimePicker
                          onStartChange={(d) =>
                            onChange({ ...value, start_date: d })
                          }
                          startValue={value?.start_date}
                          onEndChange={(d) =>
                            onChange({ ...value, end_date: d })
                          }
                          endValue={value?.end_date}
                          error={
                            (errors.accessTime &&
                              (errors.accessTime.start_date?.message ||
                                ('end_date' in errors.accessTime &&
                                  (errors.accessTime.end_date as FieldError)
                                    .message))) ||
                            errors.accessTime?.message ||
                            undefined
                          }
                          timeZoneAbbreviation={timeZoneAbbreviation}
                        />
                      </GraniteLabel>
                    )
                  }
                />
                <div className="mt-2 text-sm text-content-base-subdued">
                  Dispatches outside of 8:00 AM - 5:00 PM on Mon-Fri, as well as
                  holidays and weekends, are considered same-day dispatches.
                </div>
              </div>
              <GraniteLabel
                label="Purchase order # (optional)"
                subtitle="Multiple entries should be separated with commas"
              >
                <GraniteInput
                  className="mb-6"
                  error={errors?.po_number?.message}
                  {...register(`po_number`)}
                />
              </GraniteLabel>
              <Surface className="col-start-2 row-start-1 row-end-5 flex w-full flex-col items-start gap-4 self-start">
                <PriorityBadge priority={priority || fallbackPriority} />
                <div className="text-sm text-content-base-subdued">
                  Dispatch priority level is automatically determined based on
                  dispatch date & access time requested above. Dispatch priority
                  will directly affect the estimated dispatch fee and hourly
                  rates.
                </div>
                <Divider className="w-full" />
                {type === 'single' && (
                  <PriorityInfo estimatedCost={estimatedCost} />
                )}
              </Surface>
            </FormSection>
          </>
        )}
        {type === 'single' && (
          <>
            <Divider />
            <FormSection title="Location details" gridClassName="grid-cols-2">
              <div className="col-span-full">
                <Controller
                  name="site"
                  control={control}
                  render={({ field }) => (
                    <GraniteLabel label="Address" element="div">
                      <SearchAddressBar
                        {...field}
                        value={site}
                        onAddressSelected={(address) => {
                          // RHF bug workaround, see comment on the `watch('site')` line above
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          setValue('site', address);
                        }}
                        error={errors.site?.message && 'An address is required'}
                      />
                    </GraniteLabel>
                  )}
                />
              </div>
              <Controller
                name="content.locationName"
                control={control}
                render={({ field }) => (
                  <GraniteInput
                    {...field}
                    label="Location name"
                    subtitle="Name of the business that the technician should look for upon arrival"
                    className="col-start-1"
                    //@ts-expect-error this exits
                    error={errors?.content?.locationName?.message}
                  />
                )}
              />
              <GraniteInput
                label="Location number (optional)"
                className="col-start-2 mt-[42px]"
                {...register('content.locationNumber')}
                //@ts-expect-error this exits
                error={errors.content?.locationNumber?.message}
              />
            </FormSection>
          </>
        )}

        <Divider />
        <FormSection
          title="Scope of work & special instructions"
          gridClassName="grid-cols-1"
        >
          <div className="flex flex-col gap-4 rounded bg-input-background-filled p-4">
            <div>
              <h3 className="mb-2 text-xl font-bold text-status-info-default">
                Scope of work
              </h3>
              <p className="text-sm text-content-base-subdued">
                These are step-by-step instructions of what needs to be done on
                site. This may include pertinent equipment info, circuit info,
                location info, or any deliverables expected after completion.
              </p>
            </div>
            <Divider />
            <p className="whitespace-pre-line text-base font-bold text-input-content-filled">
              {scopeOfWork}
            </p>
          </div>
          <GraniteTextArea
            label="Additional notes/requests (optional)"
            subtitle="Please provide any additional instructions of what needs to be done on site that’s not already included above."
            inputClassName="resize-y"
            error={errors.scopeOfWorkInstructions?.message}
            {...register('scopeOfWorkInstructions')}
          />
          <GraniteTextArea
            label="Special instructions (optional)"
            subtitle="Door/access codes, call LCON 30 minutes prior to arrival, etc."
            className="col-span-full"
            error={errors.specialInstructions?.message}
            {...register('specialInstructions')}
          />
          <StandardTools />
          <NonStandardToolsFieldTextField control={control} />
        </FormSection>
        <Divider />
        <FormSection title="Attachments">
          <Controller
            name="attachments"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FileUpload
                label="Upload any additional files (optional)"
                subtitle="Documents pertaining to dispatch such as floor plan, install guide, survey document, photos of broken items, etc. Up to 25 MB of documents (.pdf, .doc, .csv, .xlsx) and images (.png, .jpg, .jpeg) are allowed"
                className="col-span-2"
                accept=".pdf,.docx,.doc,.xlsx,.csv,.png,.jpeg,.jpg"
                multiple
                value={value}
                onChange={(files) => {
                  onChange(files);
                }}
              />
            )}
          />
        </FormSection>
      </LetUsHelpBaseForm>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        <div>
          <h2 className="mb-1 text-2xl font-bold text-content-base-default">
            Estimated price of dispatch
          </h2>
          <h3 className="text-base font-bold text-content-base-subdued">
            Duration may change once the technician is on site.
          </h3>
        </div>
        {type === 'single' && <EstimatedCost estimatedCost={estimatedCost} />}
        {type === 'multi' && locations && locations.length > 1 && (
          <>
            {summaryEstimatedCost.map(
              (cost: EstimatedCostResponse, index: number) => (
                <Accordion
                  isDark={true}
                  key={index}
                  items={[
                    {
                      title: (
                        <p className="font-bold">
                          Priority {cost?.priority?.split('')[1]}
                        </p>
                      ),
                      content: (
                        <div className="mb-4 flex flex-col gap-3 bg-background-base-surface-1">
                          <div className="flex w-full items-center justify-between ">
                            <p className="text-base font-bold text-content-base-subdued">
                              # of dispatches requested
                            </p>
                            <p className="text-base font-bold text-content-base-default">
                              {cost.priority &&
                                `${estimatedCosts[cost.priority].length}`}
                            </p>
                          </div>
                          <div className="flex w-full items-center justify-between ">
                            <p className="text-base font-bold text-content-base-subdued">
                              Estimated duration per dispatch
                            </p>
                            <p className="text-base font-bold text-content-base-default">
                              {cost.hours}
                            </p>
                          </div>
                          <div className="flex w-full items-center justify-between ">
                            <p className="text-base font-bold text-content-base-subdued">
                              Hourly rate per dispatch
                            </p>
                            <p className="text-base font-bold text-content-base-default">
                              ${cost.rate_per_hour.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex w-full items-center justify-between ">
                            <p className="text-base font-bold text-content-base-subdued">
                              Dispatch fee per dispatch
                            </p>
                            <p className="text-base font-bold text-content-base-default">
                              ${cost.dispatch_fee_dollars.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex w-full items-center justify-between ">
                            <p className="text-base font-bold text-content-base-subdued">
                              Basic materials fee per dispatch
                            </p>
                            <p className="text-base font-bold text-content-base-default">
                              ${cost.misc_fee_dollars.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex w-full items-center justify-start">
                            <p className="text-sm font-semibold text-content-base-subdued">
                              The basic materials fee applies to all dispatches.
                              Any materials charges in excess of the basic
                              materials fee will be calculated once dispatch is
                              complete.
                            </p>
                          </div>
                          <Divider />
                        </div>
                      ),
                      footer: (
                        <div className="flex w-full items-center justify-between px-4">
                          <p className="text-xl font-bold text-content-base-default">
                            Estimated Subtotal
                          </p>
                          <p className="text-xl font-bold text-content-accent-default">
                            ${cost.total.toFixed(2)}
                          </p>
                        </div>
                      ),
                    },
                  ]}
                />
              ),
            )}
            <div className="flex w-full items-center justify-between rounded bg-button-background-tertiary-default p-4">
              <h1 className="text-xl font-bold text-content-flip-default">
                Total estimated price
              </h1>
              <p className="text-xl font-bold text-content-flip-default">
                ${memoizedTotal.toFixed(2)}
              </p>
            </div>
          </>
        )}

        <div className="flex gap-4">
          <GraniteButton
            className="w-full"
            variant="secondary"
            size="large"
            onClick={() => navigate(-1)}
          >
            Back
          </GraniteButton>
          <GraniteButton
            className="w-full"
            variant="primary"
            size="large"
            type="submit"
            form="ticket-review-form"
            disabled={createTicketMutation.isLoading || isSubmitting}
          >
            Submit
          </GraniteButton>
        </div>
      </div>
      {type === 'single' && (
        <ReviewTicketConfirmationDialog
          {...modalProps}
          formData={dynamicProps?.formData}
          estimatedCost={dynamicProps?.estimatedCost}
          onConfirmation={onDialogConfirmation}
          ticketType={userFacingTicketType}
          product={userFacingProduct}
          priority={priority || fallbackPriority}
        />
      )}
      {type === 'multi' && (
        <BulkModalLocations
          {...bulkModalProps}
          locations={locations}
          uploadLocations={uploadLocations}
          isReview={true}
        />
      )}
      <DuplicateTicketWarningDialog
        duplicates={duplicateModalProps.dynamicProps}
        {...duplicateModalProps}
      />
    </LetUsHelpLayout>
  );
};

export const ReviewFormWrapper = () => {
  const { dispatch, state, draftId } = useLetUsHelpContext();
  const { running } = useProductTourContext();

  if (running) {
    return <ReviewSkeleton />;
  }
  if (state.state !== 'Review') {
    if (state.state === 'TicketDetails') {
      // Coming from TicketDetails using the browser forward button
      const ticketDetail = TicketDetailFormSchemas.safeParse(
        state.ticketDetailForm,
      );

      if (ticketDetail.success) {
        dispatchReviewFormFromTicketDetailUnionForm(
          ticketDetail.data,
          dispatch,
          state.getStartedQuestionnaire,
        );
      } else return <Navigate to="/tech-express/let-us-help/details" />;
    } else {
      // User is trying to access the route directly
      return <Navigate to="/tech-express/let-us-help" />;
    }
    return <Fragment />;
  }

  return (
    <Review
      draftId={draftId}
      dispatch={dispatch}
      questionnaire={state.getStartedQuestionnaire}
      reviewFormDefaultValue={state.reviewForm}
      template={state.template}
    />
  );
};
