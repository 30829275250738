import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Card from './BaseComponents/Card';
import { ReactComponent as LeftCircles } from 'assets/images/left_circles.svg';
import { ReactComponent as RightCircles } from 'assets/images/right_circles.svg';
import { ReactComponent as TechnoDots } from 'assets/images/techno_dots.svg';
import { ReactComponent as LeftCirclesSlider } from 'assets/images/left_circles_slider.svg';
import { ArrowUp, GlobeOutline, ReceiptOutline } from 'react-ionicons';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import './AccessExpress.css';
import Carousel from './BaseComponents/Carousel/Carousel';
import {
  SearchAddressBar,
  SearchAddressBarRef,
} from './BaseComponents/SearchAddressBar/SearchAddressBar';
import MultiCheckboxOptions from './BaseComponents/MultiCheckboxOptions/MultiCheckboxOptions';
import Loader from 'components/Loader';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import StarSvg from './Svg/StarSvg';
import {
  createQuoteRequest,
  simpleFlowLocation,
} from 'screens/OpenQuote/ChooseServices/utils';
import { useAuth0User } from 'hooks/useAuth0User';
import { QuoteLocation } from 'api/accessexpress/schema';
import { DisplayForRole } from 'components/DisplayForRole/DisplayForRole';
import { TokenUserRoles } from 'api/users/schemas/Users';
import Menu from './BaseComponents/Menu/Menu';

const BackgroundWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-gradient-radial shadow-lg-custom relative">
    <div className="pointer-events-none relative w-full">
      <div className="absolute left-0 z-0">
        <LeftCircles />
      </div>
      <div className="absolute left-[156px] top-10">
        <StarSvg width="8" height="10" />
      </div>
      <div className="absolute left-[190px] top-[338px]">
        <StarSvg width="14" height="14" />
      </div>
      <div className="absolute left-[80px] top-[485px]">
        <StarSvg />
      </div>
      <div className="absolute right-0 z-0">
        <RightCircles />
      </div>
    </div>
    {children}
  </div>
);

const texts = [
  'at your fingertips',
  'within your control',
  'at your convenience',
  'yours to explore',
  'has been reimagined',
];

const HeroSection = React.forwardRef<HTMLDivElement>((props, ref) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      ref={ref}
      className="z-10 flex flex-col gap-8 px-[180px] pt-[90px] xl:px-[320px]  xl:pt-[180px]"
    >
      <div className="flex w-full flex-col text-center font-bold">
        <p className="text-[40px] text-content-base-default xl:text-[52px]">
          The future of telecom
        </p>
        <div className="text-container">
          {texts.map((text, index) => (
            <p
              key={index}
              className={`scrolling-text hero-section-gradient-text text-[40px] text-content-base-default text-transparent xl:text-[52px] ${
                index === currentTextIndex ? 'active' : ''
              } ${
                index === (currentTextIndex - 1 + texts.length) % texts.length
                  ? 'previous'
                  : ''
              }`}
            >
              {text}
            </p>
          ))}
        </div>
      </div>
      <p className="text-center text-xl text-content-base-subdued">
        Customizable network solutions made simple with AccessExpress.
      </p>
    </div>
  );
});

HeroSection.displayName = 'HeroSection';

interface SearchSectionProps {
  searchBarRef: RefObject<SearchAddressBarRef>;
}

const SearchSection = ({ searchBarRef }: SearchSectionProps) => {
  const [addressValue, setAddressValue] = useState<
    QuoteLocation & { street?: string | null | undefined }
  >();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userInfo = useAuth0User();

  const navigate = useNavigate();

  const handleOptionClick = (options: string[]) => {
    setSelectedOptions(options);
  };
  const handleSearchClick = async () => {
    if (selectedOptions.length === 0 && !addressValue) return;
    setIsLoading(true);
    if (selectedOptions.length === 1 && selectedOptions[0] === 'broadband') {
      if (addressValue) {
        const quouteRequest = await createQuoteRequest(
          userInfo,
          //@ts-expect-error err
          simpleFlowLocation({
            ...addressValue,
            address_line_1: addressValue.address_line_1
              ? addressValue.address_line_1
              : addressValue.street!,
          }),
        );
        navigate(
          '/access-express/open-quote/select-service-bundle/' +
            quouteRequest.id,
          {
            state: {
              isSimpleFlow: true,
            },
          },
        );
      }
    } else if (selectedOptions.length > 0) {
      navigate('/access-express/open-quote/choose-services', {
        state: { selectedOptions, addressValue },
      });
    }
  };

  const buttonLabel =
    addressValue && !isLoading
      ? 'Check availability'
      : isLoading
        ? 'Checking availability'
        : 'Search';

  const ButtonComponent = (
    <GraniteButton
      size="large"
      onClick={handleSearchClick}
      disabled={addressValue && selectedOptions.length === 0}
      className={clsx(
        'max-h-12',
        isLoading &&
          '!cursor-default !border-button-stroke-secondary-pressed !bg-button-background-secondary-pressed !text-button-content-secondary-pressed',
      )}
    >
      {buttonLabel}
      {isLoading && <Loader animationClassname="!w-5 !h-5" />}
    </GraniteButton>
  );
  return (
    <>
      <div className="bg-surface-2-subdued shadow-lg-custom w-[800px] max-w-[800px] self-center rounded-[4px] p-6">
        <div className="flex gap-2">
          <SearchAddressBar
            ref={searchBarRef}
            onAddressSelected={setAddressValue}
            className="w-[80%] flex-1"
            value={addressValue}
          />
          {addressValue && !isLoading ? (
            <DisplayForRole
              roles={[TokenUserRoles.VIEWER, TokenUserRoles.USER]}
            >
              {ButtonComponent}
            </DisplayForRole>
          ) : (
            ButtonComponent
          )}
        </div>
      </div>
      {addressValue && (
        <OptionsSection
          selectedOptions={selectedOptions}
          handleOptionClick={handleOptionClick}
        />
      )}
    </>
  );
};

const OptionsSection = ({
  selectedOptions,
  handleOptionClick,
}: {
  selectedOptions: string[];
  handleOptionClick: (options: string[]) => void;
}) => {
  return (
    <div className="flex w-[800px] gap-3 self-center">
      <MultiCheckboxOptions
        label="Broadband"
        option={'broadband'}
        value={selectedOptions}
        onClick={handleOptionClick}
        className="flex-1"
      />
      <MultiCheckboxOptions
        label="DIA (Dedicated Internet Access)"
        subText=""
        option={'dia'}
        value={selectedOptions}
        onClick={handleOptionClick}
        className="flex-1"
      />
    </div>
  );
};

const OrderingSection = ({ scrollToHero }: { scrollToHero: () => void }) => {
  const navigate = useNavigate();

  const onGetStartedClick = () => {
    scrollToHero();
  };

  const onViewQuotesClick = () => {
    navigate('/access-express/index');
  };

  return (
    <div className="relative flex h-full w-full items-start justify-center pl-8 pr-8">
      <div className="absolute top-[35px] z-0">
        <TechnoDots />
      </div>
      <div className="z-10 flex flex-col gap-[40px] self-center pb-[140px] pt-[140px]">
        <p className="text-center text-[28px] font-bold text-content-base-default">
          Ordering with Granite has never been this easy.
        </p>
        <div className="flex justify-center gap-8">
          <Card
            label="Order services"
            subText="Enter your locations, then order services that work for you."
            buttonLabel="Get started"
            onButtonClick={onGetStartedClick}
            icon={<GlobeOutline color="#82F0FF" />}
          />
          <Card
            label="View my quotes"
            subText="View and manage all of your company's Granite360 generated quote requests."
            buttonLabel="Go to quotes"
            onButtonClick={onViewQuotesClick}
            icon={<ReceiptOutline color="#82F0FF" />}
          />
        </div>
      </div>
    </div>
  );
};

const ConnectivitySection = ({
  scrollToHero,
}: {
  scrollToHero: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="access-express-svg flex h-[603px] w-full flex-col justify-start bg-cover px-[64px] py-12">
      <div className="mb-16 flex flex-col items-center justify-center gap-4 text-center font-bold">
        <p className="text-[44px] leading-[56px] text-content-base-default">
          What are you waiting for?
        </p>
        <p
          onMouseEnter={() => setIsHovered(true)}
          className={`your-journey ${isHovered ? 'your-journey-hovered' : ''}`}
        >
          Your journey to connectivity starts here
        </p>
      </div>
      <div className="flex  h-[140px] w-[450px] flex-col items-center justify-center gap-4 self-center rounded border-t border-background-base-surface-3 bg-[#222231B2] p-6 shadow-elevation4 backdrop-blur">
        <p className="text-xl font-bold text-content-base-default">
          Check availability at your location
        </p>
        <GraniteButton
          variant="secondary"
          className="self-center"
          onClick={scrollToHero}
          size="large"
        >
          Let&apos;s go <ArrowUp color="inherit" />
        </GraniteButton>
      </div>
    </div>
  );
};

const AccessExpress = () => {
  const heroSectionRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<{ focusInput: () => void }>(null);

  const scrollToHero = () => {
    const offsetTop = heroSectionRef.current?.offsetTop || 0;
    const additionalOffset = 100;

    window.scrollTo({
      top: offsetTop - additionalOffset,
      behavior: 'smooth',
    });
    setTimeout(() => {
      searchBarRef.current?.focusInput();
    }, 1000);
  };

  return (
    <div className="h-full w-full">
      <Menu />
      <BackgroundWrapper>
        <HeroSection ref={heroSectionRef} />
        <div className="mt-8 flex flex-col justify-center gap-8 pb-[60px] xl:pb-[120px] ">
          <SearchSection searchBarRef={searchBarRef} />
        </div>
      </BackgroundWrapper>
      <main className="relative h-[680px] overflow-hidden px-[64px] pb-[80px] pt-[120px]">
        <span className="absolute -right-4 -top-72">
          <LeftCirclesSlider />
        </span>
        <Carousel />
      </main>
      <OrderingSection scrollToHero={scrollToHero} />
      <ConnectivitySection scrollToHero={scrollToHero} />
    </div>
  );
};

export default AccessExpress;
