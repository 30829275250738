import { useAuth0User } from 'hooks/useAuth0User';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ContactGroupFormType } from './schema';
import Searchbar from 'components/Table/SearchBar';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { SingleValue } from 'react-select';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { ColumnDef } from '@tanstack/react-table';
import { DeviceType } from '../ContactGroups';
import Checkbox from 'components/Checkbox';
import { PaginableRequest } from 'api/common-schemas';
import { getDevicesMockData } from '../../utils';

const DevicesForm = () => {
  const [search, setSearch] = useState<string>('');
  const [statusFilter, setStatusFilter] =
    useState<SingleValue<OptionType>>(null);

  const { isViewerRole } = useAuth0User();
  const { control, setValue, watch } = useFormContext<ContactGroupFormType>();

  const onChangeStatus = (status: SingleValue<OptionType>) => {
    setStatusFilter((prev) => (prev?.value !== status?.value ? status : null));
  };

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'name', desc: true }],
  });

  const getContactGroups = (args: PaginableRequest) => {
    // API call
    return Promise.resolve(getDevicesMockData(args));
  };

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    getContactGroups,
    {
      search,
      sortingQueryParams,
      statusFilter,
    },
    ['alert-contact-groups-device-table', search, sortingQueryParams],
    {
      refetchOnMount: true,
    },
    { pageIndex: 0, pageSize: 10 },
  );

  const onChangeAll = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue('devices', e.target.checked ? tablePageData : []);

  const tableHeaders: ColumnDef<DeviceType>[] = [
    {
      id: 'checkbox',
      header: () => {
        const isAllSelected = tablePageData.every(
          (row) => !!watch('devices')?.find((field) => field.name === row.name),
        );
        const isSomeSelected = tablePageData.some(
          (row) =>
            !!watch('devices')?.find((field) => field.name === row.name) &&
            !isAllSelected,
        );
        return (
          <Checkbox
            checked={isAllSelected}
            isPartiallChecked={isSomeSelected}
            onChange={onChangeAll}
            className="mx-1"
            disabled={isViewerRole}
          />
        );
      },
      cell: (row) => {
        return (
          <Controller
            name="devices"
            control={control}
            render={({ field }) => {
              const isChecked = !!field.value?.find(
                (d) => d.name === row.row.original.name,
              );
              const valueOnChange = isChecked
                ? field.value?.filter((s) => s.name !== row.row.original.name)
                : [...(field.value || []), row.row.original];

              return (
                <Checkbox
                  disabled={isViewerRole}
                  checked={isChecked}
                  onChange={() => field.onChange(valueOnChange)}
                  className="mx-1"
                />
              );
            }}
          />
        );
      },
    },
    {
      header: 'Device',
      accessorKey: 'name',
      meta: { align: 'left' },
    },
    {
      header: 'Serial number',
      accessorKey: 'serial',
      meta: { align: 'left' },
    },
    {
      header: 'Account',
      accessorKey: 'account',
      meta: { align: 'left' },
    },
  ];

  return (
    <div>
      <div className="flex gap-4">
        <Searchbar
          placeholder="Search"
          onSearch={setSearch}
          onQueryClear={() => {
            setSearch('');
          }}
          clearAllValues={search === ''}
        />
        <GraniteSelect
          className="max-w-[222px]"
          placeholder="Filter by status"
          isClearable={false}
          isSearchable={false}
          options={[
            { value: 'Selected', label: 'Selected' },
            { value: 'Unselected', label: 'Unselected' },
          ]}
          onChange={onChangeStatus}
          value={statusFilter}
        />
      </div>
      {/* temporary solution */}
      <div className="mt-6 w-full">
        <ServerPaginatedTable
          data={tablePageData as DeviceType[]}
          columns={tableHeaders}
          title="nocexpress edit alert contact groups devices"
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          emptyDataElement={<EmptySearchResults />}
          paginationSizeStored={10}
          paginationVariant="short"
          pageSizeMenuPlacement="top"
          variant="small"
          tableContainerClassName="max-h-[440px]"
          {...paginatedTableProps}
        />
      </div>
    </div>
  );
};

export default DevicesForm;
