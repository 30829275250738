import { nullToUndefined } from 'shared/util/schema-utils';
import { z } from 'zod';

export const UserRoles = z.enum([
  'Viewer',
  'User',
  'Company Admin',
  'Super Admin',
  'Welcome User',
]);
export type UserRole = z.infer<typeof UserRoles>;

export const EditUserRoleRequestSchema = z.object({
  role: UserRoles,
  email: z.string(),
});

export type EditUserRoleRequest = z.infer<typeof EditUserRoleRequestSchema>;

export const EditUserRoleResponseSchema = z.object({
  name: z.string(),
  email: z.string(),
  phone_number: z.string().nullish().transform(nullToUndefined),
  secondary_phone_number: z.string().nullish().transform(nullToUndefined),
  secondary_email: z.string().email(),
  company_title: z.string().nullish().transform(nullToUndefined),
  department: z.string().nullish().transform(nullToUndefined),
  blocked: z.boolean().nullish().transform(nullToUndefined),
});

export type EditUserRoleResponse = z.infer<typeof EditUserRoleResponseSchema>;
