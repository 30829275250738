import { LETTERS_NUMBERS_SPACES_HYPHENS_APOSTROPHES_COMMAS_PERIODS_REGEXP_LABEL } from 'shared/constants/error-labels';
import { LETTERS_NUMBERS_SPACES_HYPHENS_APOSTROPHES_COMMAS_PERIODS_REGEXP } from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

export const ContactGroupSchema = z.object({
  name: z
    .string()
    .min(2, { message: 'Group name must be at least 2 characters long.' })
    .max(100, {
      message: 'Group name must be no more than 100 characters long.',
    })
    .regex(LETTERS_NUMBERS_SPACES_HYPHENS_APOSTROPHES_COMMAS_PERIODS_REGEXP, {
      message:
        LETTERS_NUMBERS_SPACES_HYPHENS_APOSTROPHES_COMMAS_PERIODS_REGEXP_LABEL,
    }),
  recipients: z
    .array(z.object({ email: z.string().email().nonempty() }))
    .nonempty({ message: 'At least one email address is required.' }),
  devices: z
    .array(
      z.object({
        name: z.string(),
        serial: z.string(),
        account: z.string(),
        // status: z.enum(['Selected', 'Unselected']),
      }),
    )
    .optional(),
});

export type ContactGroupFormType = z.infer<typeof ContactGroupSchema>;
