import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddEmailRecipient from 'screens/LetUsHelp/utils/AddEmailRecipient';
import { ContactGroupFormType } from './schema';
import ContactEmailTag from 'components/ContactEmailTag/ContactEmailTag';
import { useAuth0User } from 'hooks/useAuth0User';
import { DisplayForRole } from 'components/DisplayForRole/DisplayForRole';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import clsx from 'clsx';

const GroupForm = () => {
  const { isViewerRole } = useAuth0User();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ContactGroupFormType>();

  const {
    append,
    fields: recipients,
    remove,
  } = useFieldArray({
    name: 'recipients',
    control,
  });

  return (
    <div className="flex flex-col gap-6">
      <GraniteInput
        {...register('name')}
        label="Group name"
        placeholder="Group name"
        error={errors.name?.message}
        disabled={isViewerRole}
      />
      <div>
        <DisplayForRole roles={[TokenUserRoles.COMPANY_ADMIN]}>
          <AddEmailRecipient
            appendFunc={append}
            label="Email recipients"
            placeholder="Email address"
            buttonClassName="z-10"
            error={errors.recipients?.message}
          />
        </DisplayForRole>
        {isViewerRole && <GraniteLabel label="Email recipients" />}
        {recipients.length ? (
          <div
            className={clsx('flex flex-wrap gap-2', !isViewerRole && 'mt-4')}
          >
            {recipients?.map((recipient, i) => (
              <ContactEmailTag
                key={i}
                index={i}
                email={recipient.email}
                remove={remove}
                {...(isViewerRole && { remove: undefined, disabled: true })}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GroupForm;
