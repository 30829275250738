import { ExtendedTicket } from 'api/techexpress/schema';
import {
  MACTicketTypes,
  ProductTypes,
} from 'screens/LetUsHelp/BaseComponents/schemas';
import { WhyTechnicianEnum } from 'screens/LetUsHelp/GetStarted/schemas';
import { TicketDetailForms } from 'screens/LetUsHelp/TicketDetails/DefinedFormTypes';

export const getFindLocationMockData = () => ({
  findLocationOrServiceForm: {
    identifier: { value: 'Address', label: 'Address' },
    service: [{ label: 'Mock Service', value: 'mock_service' }],
    service_identifier: [
      { label: 'Mock Identifier', value: 'mock_identifier' },
    ],
    service_category: { label: 'Mock Category', value: 'mock_category' },
    selectedIdentifier: {
      address_line_1: '123 Mock St',
      city: 'Mocktown',
      state: 'Mock State',
      zip: '12345',
      account_name: 'Mock Account',
      site_name: 'Mock Site',
      id: 'mock_id',
      site_id: 'mock_site_id',
    },
  },
  services: [],
  bulkLocations: [],
  serviceOptions: [
    { label: 'Mock Service 1', value: 'mock_service_1' },
    { label: 'Mock Service 2', value: 'mock_service_2' },
  ],
});

const currentDate = new Date();
const oneMonthLater = new Date(
  currentDate.setMonth(currentDate.getMonth() + 1),
);

export const getLetUsHelpMockData = () => ({
  whyTechnician: WhyTechnicianEnum.enum.EquipmentInstall,
  type: 'single',
  content: {
    type: 'single',
    locationName: 'Mock Location',
    attachments: [],
  },
  site: {
    address_line_1: '123 Mock St',
    city: 'Mocktown',
    state: 'Mock State',
    zip: '12345',
    parent_name: 'Mock Account',
    name: 'Mock Site',
    id: 3568999,
    site_id: 'mock_site_id',
  },
  dispatchDate: new Date(oneMonthLater.setDate(14)), // Assuming you want the 14th of the next month
  accessTime: {
    scheduling_type: 'Hard Start',
    start_date: new Date(oneMonthLater.setDate(12)), // Assuming you want the 12th of the next month
  },
  attachments: [],
});

export const getTicketDetailsMockData = () => ({
  formType: TicketDetailForms.enum.EquipmentInstall,
  whichEquipment: 'Mock Data',
  whereInstall: 'Mock Data',
  installOrTroubleShootingGuideLV: { label: 'No', value: 'No' },
  techToTestWith: { techToTestWith: 'No' },
  nonStandardTools: { isNeeded: false },
  itTestingContacts: [],
  attachments: [],
});

export const getTicketReviewMockData = () => ({
  type: 'single',
  content: {
    type: 'single',
    localContact: [{ name: 'Mock', phoneNumber: '3234567891' }],
    locationName: 'Mock Location',
    attachments: [],
  },
  dispatchDate: new Date(oneMonthLater.setDate(14)), // Assuming you want the 14th of the next month
  accessTime: {
    scheduling_type: 'Hard Start',
    start_date: new Date(oneMonthLater.setDate(12)), // Assuming you want the 12th of the next month
  },
  product: ProductTypes.enum.FieldServicesMAC,
  ticketType: MACTicketTypes.enum.EquipmentInstall,
  scopeOfWork: 'Mock scope of work',
  nonStandardTools: { isNeeded: false },
  site: {
    address_line_1: '123 Mock St',
    city: 'Mocktown',
    state: 'Mock State',
    zip: '12345',
    parent_name: 'Mock Account',
    name: 'Mock Site',
    id: 3568999,
    site_id: 'mock_site_id',
  },
  attachments: [],
});

export const getTicketMockData = (): ExtendedTicket => {
  return {
    address_1: '2976 S Church Street',
    automatic_email_cc: false,
    automatic_email_contact: true,
    board_name: 'Field Services - MAC',
    category: 'Active',
    city: 'Murfreesboro',
    company_id: 204915,
    company_identifier: '04718434',
    company_name: 'Valvoline',
    contact_email_address: 'bsalija@granitenet.com',
    customer_preferred_tech: null,
    customer_ticket_number: undefined,
    date_entered: new Date('2024-06-17T12:48:24Z'),
    date_resolved: undefined,
    id: 1,
    initial_description:
      'Service technician needed on site to complete a site audit.\r\n\r\nThe technician should contact test at phone number (999) 999-9999 upon arriving on site for access to the IT rooms and demarcation point.\r\n\r\n',
    last_updated: new Date('2024-06-17T12:48:24Z'),
    local_contact_name: 'test',
    local_contact_number: 9999999999,
    location_contact_email: undefined,
    location_contact_ext: undefined,
    location_name: 'test',
    location_number: undefined,
    non_standard_tools: undefined,
    prior_ticket: undefined,
    schedule: {
      id: 120529,
      scheduling_type: 'Hard Start',
      start_date: new Date('2024-06-22T16:00:00Z'),
    },
    secondary_lcon_email: undefined,
    secondary_lcon_ext: undefined,
    secondary_lcon_name: undefined,
    secondary_lcon_number: undefined,
    site_name: '04683730 - 040157 - Murfreesboro, TN [121232]',
    special_instructions: undefined,
    state_identifier: 'TN',
    status_name: 'Pending Tech Assignment',
    sub_type: 'P1',
    summary: 'Site Audit | Priority 1 | 06/22/2024 | Hard Start',
    technician_id: undefined,
    technician_name: undefined,
    technician_phone_number: undefined,
    type_name: 'Site Audit',
    zip: '37127',
  };
};

export const getNOCTicketsMockData = () => {
  return {
    data: [
      {
        address_1: '251 Yorkshire Blvd',
        address_2: '',
        city: 'Lexington',
        company_name: 'Valvoline',
        date_entered: '2024-06-11T10:45:32Z',
        g360_status: 'Open',
        id: 1,
        is_followed: true,
        issue: 'Device Offline',
        last_updated: '2024-06-12T07:14:07Z',
        location_name: 'In-house',
        service_id: '89148000005541222170',
        service_id_label: 'SIM ID',
        service_name: 'Mobility Access / M2M',
        site_name: '04773988 - Lexington KY Lab',
        state: 'KY',
        status_name: 'Open',
        summary: 'ddelsignor@granitenet.com | 3569358 | dan testing purpose',
        zip: '40509',
        configurations: [],
      },
      {
        address_1: '2444 PATTERSON RD',
        address_2: '',
        city: 'Grand Junction',
        company_name: 'Valvoline',
        date_entered: '2024-06-13T15:33:04Z',
        g360_status: 'Pending Action',
        id: 2,
        is_followed: false,
        issue: 'Informational',
        last_updated: '2024-06-13T15:36:32Z',
        location_name: 'In-house',
        service_id: '89148000009496338171',
        service_id_label: 'SIM ID',
        service_name: 'Mobility Access / M2M',
        site_name: '04686661 - GT0001 - Grand Junction, CO (Franchise)',
        state: 'CO',
        status_name: 'Pending RCA',
        summary: 'hviroliya@granitenet.com | 3570870 | test1222',
        zip: '81505',
        configurations: [],
      },
      {
        address_1: '251 Yorkshire Blvd',
        address_2: '',
        city: 'Lexington',
        company_name: 'Valvoline',
        date_entered: '2024-06-13T12:09:06Z',
        g360_status: 'Pending Customer',
        id: 3,
        is_followed: false,
        issue: 'Device Offline',
        last_updated: '2024-06-13T12:13:56Z',
        location_name: 'In-house',
        service_id: '89148000005541222170',
        service_id_label: 'SIM ID',
        service_name: 'Mobility Access / M2M',
        site_name: '04773988 - Lexington KY Lab',
        state: 'KY',
        status_name: 'Monitoring/Cleared',
        summary:
          'hviroliya@granitenet.com | 3569358 | Initial description of the issue - Testing Purpose',
        zip: '40509',
        configurations: [],
      },
    ],
    metadata: {
      page: 1,
      page_size: 3,
      total_items: 3,
      total_pages: 1,
    },
  };
};

export const getNOCTicketDetailsMockData = () => {
  return {
    id: 1,
    summary: 'Mock Summary',
    city: 'Mock City',
    issue: 'Mock Issue',
    state_identifier: 'MC',
    zip: '12345',
    item: null,
    status_name: 'Open',
    last_updated: new Date().toISOString(),
    date_entered: new Date().toISOString(),
    company_name: 'Mock Company',
    site_name: 'Mock-Site',
    address_1: '123 Mock St',
    address_2: 'Suite 100',
    location_name: 'Mock Location',
    customer_ticket_number: '123456',
    prior_ticket: 987654,
    initial_description: 'This is a mock description for the initial issue.',
    tns_impacted: 'Mock TNS',
    type_name: 'Mock Type',
    sub_type: 'Mock Subtype',
    technician_name: 'John Doe',
    local_contact_name: 'Jane Smith',
    local_contact_number: '1234567890',
    special_instructions: 'Handle with care',
    attachments: [],
    automatic_email_contact: true,
    contact_email_address: 'test@example.com',
    automatic_email_cc: true,
    automatic_email_cc_address: 'cc@example.com',
    maintenance_window: '9 AM - 5 PM',
    service_id_label: 'Service ID',
    chronic_flag: false,
    sites: [
      {
        id: '1',
        address_line_1: '123 Mock St',
        city: 'Mock City',
        state: 'MC',
        zip: '12345',
        account_name: 'Mock Account',
        maintenance_window: '9 AM - 5 PM',
        is_chronic: false,
        parent_account: 'Parent Mock Account',
      },
    ],

    g360_escalated: false,
    escalation_status: 'No escalation',
    g360_status: 'Active',
    configurations: [
      {
        name: 'Mock Account',
        type: 'Mock Type',
      },
    ],
    priority: 'High',
    company_identifier: 'COMP123456',
    is_followed: false,
    additional_contacts: [
      { type: 'Local Contact', name: 'Jane Smith', number: '1234567890' },
    ],
    access_hours: '8:00AM - 5:00PM',
    technician_dispatch_approved: true,
  };
};
